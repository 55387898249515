import { Button, Form, PaginationProps, Table, Tooltip } from 'antd';
import React, { useEffect, useState } from 'react';
import { MdRefresh } from 'react-icons/md';
import { approveOrderCancellation, getCancelledOrders } from './api';
import {
  ICancelledOrderItemProps,
  ICancelledOrderJourney,
  ICancelledOrdersResponseProps
} from '../../../../../interfaces/cancelledOrders';
import { ColumnsType } from 'antd/es/table';
import GetStatus from '../../../../../utils/GetStatus';
import dayjs from 'dayjs';
import OrderIdsModal from './Partials/OrderIdsModal';
import JourneyModal from './Partials/JourneyModal';
import ConfirmationModal from './Partials/ConfirmationModal';
import GetOrderByIdFilter from '../../../../CommonComponents/TableFilterComponents/GetOrderById';
import GetOrderByStatus from './utils/GetOrderByStatus';
import GetCopyTextDiv from '../../../../CommonComponents/GetCopyTextDiv';

const CancelledOrdersList = () => {
  const [tableLoading, setTableLoading] = useState<boolean>(false);
  const [tableData, setTableData] = useState<ICancelledOrdersResponseProps>({
    orderList: [],
    count: 0
  });
  const [orderIdsModal, setOrderIdsModal] = useState<boolean>(false);
  const [journeyModal, setJourneyModal] = useState<boolean>(false);
  const [confirmModal, setConfirmModal] = useState<boolean>(false);
  const [confirmLoading, setConfirmLoading] = useState<boolean>(false);
  const [journeyData, setJourneyData] = useState<ICancelledOrderJourney[]>([]);
  const [selectedOrderIds, setSelectedOrderIds] = useState<string[]>([]);
  const [selectedApproveId, setSelectedApproveId] = useState<string>('');
  const [currentPage, setCurrentPage] = useState(1);
  const [pageLimit, setPageLimit] = useState(10);

  const [orderId, setOrderId] = useState<string>('');
  const [orderStatus, setOrderStatus] = useState<string>('');

  const [orderIdSearchForm] = Form.useForm();
  const [orderStatusForm] = Form.useForm();

  useEffect(() => {
    handleGetCancelledOrders();
  }, [pageLimit, currentPage, orderId, orderStatus]);

  const handleGetCancelledOrders = async () => {
    await getCancelledOrders({
      setLoading: setTableLoading,
      setData: setTableData,
      pageLimit: pageLimit,
      skip: currentPage,
      orderId: orderId,
      orderStatus: orderStatus
    });
  };

  const handleOnClickOrderIds = (orderIds: string[]) => {
    setSelectedOrderIds(orderIds);
    setOrderIdsModal(true);
  };

  const handleOnClickJourney = (journeyData: ICancelledOrderJourney[]) => {
    setJourneyData(journeyData);
    setJourneyModal(true);
  };

  const tableColumns: ColumnsType<ICancelledOrderItemProps> = [
    {
      width: 150,
      dataIndex: 'orderId',
      render: (orderIds) => (
        <>
          {orderIds?.length === 0 && <p>NA</p>}
          {orderIds?.length === 1 && <GetCopyTextDiv text={orderIds[0]} />}
          {orderIds?.length > 1 && (
            <Button
              className="primary-button h-[30px]"
              onClick={() =>
                handleOnClickOrderIds(orderIds)
              }>{`View ( ${orderIds?.length} )`}</Button>
          )}
        </>
      ),
      ...GetOrderByIdFilter({
        orderId: orderId,
        setOrderId: setOrderId,
        formInstance: orderIdSearchForm,
        formId: 'orderIdSearchForm',
        placeHolder: 'Enter Order ID',
        title: 'Order IDs'
      })
    },
    {
      title: 'Date',
      dataIndex: 'createdAt',
      width: 100,
      render: (date) => dayjs(date).format('DD/MM/YYYY, hh:mm A')
    },
    {
      title: 'Journey',
      dataIndex: 'cancellationObject',
      width: 150,
      render: (journey) => (
        <Button
          className="primary-button h-[30px]"
          onClick={() => handleOnClickJourney(journey)}>{`View ( ${journey?.length} )`}</Button>
      )
    },
    {
      dataIndex: 'cancellationStatus',
      width: 150,
      render: (status) => (
        <GetStatus
          currStatus={status?.toLowerCase()}
          PROCESSING="requested"
          SUCCESS="approved"
          FAILED="rejected"
          CUSTOM_LABEL={status?.toUpperCase()}
          SHOW_TAGS
        />
      ),
      ...GetOrderByStatus({
        orderStatusForm: orderStatusForm,
        setOrderStatus: setOrderStatus,
        orderStatus: orderStatus,
        title: 'Status'
      })
    },
    {
      title: 'Action',
      width: 150,
      dataIndex: '_id',
      render: (id, item) =>
        item?.cancellationStatus === 'requested' ? (
          <Button type="primary" onClick={() => onClickApprove(id)}>
            Approve
          </Button>
        ) : (
          'Approved'
        )
    }
  ];

  const onConfirmApprove = async () => {
    await approveOrderCancellation({
      setLoading: setConfirmLoading,
      orderId: selectedApproveId,
      callBackFun: () => {
        onConfirmClose();
        handleGetCancelledOrders();
      }
    });
  };

  const onClickApprove = (id: string) => {
    setConfirmModal(true);
    setSelectedApproveId(id);
  };

  const onConfirmClose = () => {
    setConfirmModal(false);
    setSelectedApproveId('');
  };

  const onCloseOrderIdsModal = () => {
    setOrderIdsModal(false);
  };

  const onPageChange: PaginationProps['onChange'] = (pageNo, limit) => {
    setCurrentPage(pageNo);
    setPageLimit(limit);
  };

  return (
    <>
      <div className="flex gap-4 items-center mb-3">
        <h3 className="text-lg p-0 m-0">Cancelled Orders</h3>
        <Tooltip title="Refresh">
          <Button loading={tableLoading} onClick={handleGetCancelledOrders} icon={<MdRefresh />} />
        </Tooltip>
      </div>

      <Table
        columns={tableColumns}
        dataSource={tableData?.orderList}
        loading={tableLoading}
        pagination={{
          responsive: true,
          showQuickJumper: true,
          showSizeChanger: true,
          current: currentPage,
          pageSize: pageLimit,
          pageSizeOptions: [20, 30, 50],
          total: tableData?.count ? Math.ceil(tableData?.count / pageLimit) * pageLimit : 0,
          onChange: onPageChange
        }}
      />

      <OrderIdsModal
        visible={orderIdsModal}
        onClose={onCloseOrderIdsModal}
        orderIds={selectedOrderIds}
      />

      <JourneyModal
        journeyData={journeyData}
        isModalOpen={journeyModal}
        setIsModalOpen={setJourneyModal}
      />

      <ConfirmationModal
        showModal={confirmModal}
        handleClose={onConfirmClose}
        handleConfirmUpload={onConfirmApprove}
        loading={confirmLoading}
      />
    </>
  );
};

export default CancelledOrdersList;
