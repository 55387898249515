import React, { FC, useEffect, useState } from 'react';
import { Button, Table, Tabs, TabsProps, Input, PaginationProps } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { useNavigate } from 'react-router-dom';
import UserMenu from '../../ManageMenu/api';
import UsersData from '../../Users/api';
import { cartList, finexUsersListAPI, getUserDetails } from '../api';
import GetCopyTextDiv from '../../../CommonComponents/GetCopyTextDiv';
import { SearchOutlined } from '@ant-design/icons';

const FintechUserList: React.FC = () => {
  const navigate = useNavigate();
  const { UsersList } = UsersData();
  const [userList, setUserList] = useState<any[]>([]);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [pageLimit, setPageLimit] = useState<number>(20);
  const [activeTab, setActiveTab] = useState<string>('ENABLED');
  const [showNameSearch, setShowNameSearch] = useState<boolean>(false);
  const [showUserIdSearch, setShowUserIdSearch] = useState<boolean>(false);
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [searchUserId, setSearchUserId] = useState<string>('');
  const [searchEmail, setSearchEmail] = useState<string>('');
  const [count, setCount] = useState(0); 
  const [isLoading, setIsLoading] = useState(false); 
  
  
  useEffect(() => {
    const savedSearchQuery = localStorage.getItem('searchQuery');
    const savedSearchUserId = localStorage.getItem('searchUserId');
    const savedSearchEmail = localStorage.getItem('searchEmail');
    const savedTab = localStorage.getItem('activeTab');

    if (savedSearchQuery) setSearchQuery(savedSearchQuery);
    if (savedSearchUserId) setSearchUserId(savedSearchUserId);
    if (savedSearchEmail) setSearchEmail(savedSearchEmail);
    if (savedTab) setActiveTab(savedTab);

    getUsers();
  }, [pageNumber]);
  useEffect(() => {
    localStorage.setItem('searchQuery', searchQuery);
    localStorage.setItem('searchUserId', searchUserId);
    localStorage.setItem('searchEmail', searchEmail);
    localStorage.setItem('activeTab', activeTab);

    getUsers();
  }, [searchQuery, searchUserId, searchEmail, activeTab]);

  const getUsers = async (status = activeTab, pageNo = pageNumber, limit = pageLimit) => {
    setIsLoading(true);
    try {
      const userListApiRes = await finexUsersListAPI({
        pageNumber: pageNo,
        pageLimit: limit,
        status,
        firstName: searchQuery || undefined,
        userId: searchUserId || undefined, 
        email: searchEmail || undefined, 
      });
      setUserList(userListApiRes.list);
      setCount(userListApiRes.count);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };
  
  const onPageChange: PaginationProps['onChange'] = (pageNo, limit) => {
    setPageNumber(pageNo);
    setPageLimit(limit);
    getUsers(activeTab, pageNo, limit); 
  };
  
  const handleSearchByName = async () => {
    await getUsers();
    setShowNameSearch(false);
  };

  const handleSearchByUserId = async () => {
    await getUsers();
    setShowUserIdSearch(false);
  };

  const handleViewDetails = async (userDetails: any) => {
    const response = await getUserDetails(userDetails.userId);
    navigate(`/user-details-finx/${userDetails.userId}`, {
      state: { userDetails: response },
    });
  };

  const tabs: TabsProps['items'] = [
    {
      key: 'ENABLED',
      label: 'Verified Users',
    },
    {
      key: 'PENDING',
      label: 'Pending Users',
    },
  ];

  const handleClearFilters = async () => {

    setSearchQuery('');
    setSearchUserId('');
    setSearchEmail('');
    setShowNameSearch(false);
    setShowUserIdSearch(false);
    localStorage.removeItem('searchQuery');
    localStorage.removeItem('searchUserId');
    localStorage.removeItem('searchEmail');
    localStorage.removeItem('activeTab');

    await getUsers();
  };

  const columns: ColumnsType<any> = [
    {
      title: (
        <div className="relative">
          <div className="flex items-center">Name
            <SearchOutlined
              className="ml-2  text-gray-500 cursor-pointer"
              onClick={() => setShowNameSearch(!showNameSearch)} />
          </div>
          {showNameSearch && (
            <div className="absolute z-10 mt-2 w-25 bg-white p-2 shadow-lg border rounded">
              <Input
                placeholder="Search by name"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                onPressEnter={handleSearchByName}
                allowClear
              />
              <div className="mt-2 flex justify-end">
                <Button size="small" onClick={() => setShowNameSearch(false)} className="mr-2">Cancel</Button>
                <Button type="default" size="small" onClick={handleSearchByName} className='primary-button'>Search</Button>
              </div>
            </div>
          )}
        </div>
      ),
      width: '6%',
      render: (getUsersData) => (
        <>
          <h3 className="font-bold text-blue-500" onClick={() => handleViewDetails(getUsersData)}>
            {getUsersData?.firstName}
          </h3>
          <GetCopyTextDiv text={getUsersData?.email} />
        </>
      ),
      responsive: ['sm'],
    },
    {
      title: (
        <div className="relative">
          <div className="flex items-center">
            User Id
            <SearchOutlined
              className="ml-2 text-gray-500 cursor-pointer"
              onClick={() => setShowUserIdSearch(!showUserIdSearch)} />
          </div>
          {showUserIdSearch && (
            <div className="absolute z-10 mt-2 w-25 bg-white p-2 shadow-lg border rounded">
              <Input
                placeholder="Search by User ID"
                value={searchUserId}
                onChange={(e) => setSearchUserId(e.target.value)}
                onPressEnter={handleSearchByUserId}
                allowClear
              />
              <div className="mt-2 flex justify-end">
                <Button size="small" onClick={() => setShowUserIdSearch(false)} className="mr-2">Cancel</Button>
                <Button type="default" size="small" className='primary-button' onClick={handleSearchByUserId}>
                  Search
                </Button>
              </div>
            </div>
          )}
        </div>
      ),
      dataIndex: 'userId',
      key: 'userId',
      width: '6%',
      responsive: ['sm'],
      render: (id) => <GetCopyTextDiv text={id} />,
    }
  ];
  
  const additionalColumns: ColumnsType<any> = [
    {
      title: 'Pickup Address',
      width: '3%',
      align: 'center',
      render: (record) => (
        <span
          className="font-bold text-blue-500"
          style={{ textDecoration: 'underline', textDecorationStyle: 'dotted' }}
          onClick={() => navigate(`/pickupAddress-finx/${record.userId}`)}> Addresses
        </span>
      ),
      responsive: ['sm'],
    },
    {
      title: 'Charges($)',
      width: '3%',
      align: 'center',
      render: (record) => (
        <div>
          <Button className="secondary-button" onClick={() => navigate(`/charges-finx/${record.userId}`)}> Charges </Button>
        </div>
      ),
    },
    {
      title: "Booking Request",
      width: "6%",
      responsive: ["sm"],
      align: "center",
      render: (record) => (
        <div className="d-flex align-items-center">
          <Button
            className="me-3 ms-3"
            style={{ width: "140px" }}
            type="primary"
            onClick={() => navigate(`/booking-request-finx/${record.userId}`)}
          >
            Booking Request
          </Button>
          {/* <span className="font-bold text-blue-500 ml-2">
            ({record.orderCount || 0})
          </span> */}
        </div>
      ),
    },
    {
      title: 'Bulk',
      width: '4%',
      align: 'center',
      responsive: ['sm'],
      render: (record) => (
        <div>
          <Button
            className="me-3 primary-button"
            type="default"
            onClick={() => navigate(`/bulkOrder-list-finx/${record.userId}`)}>  Bulk Order </Button>
        </div>
      ),
    },
  ];
  const pendingColumns: ColumnsType<any> = [
    ...columns,
    {
      title: 'Actions',
      width: '4%',
      align: 'center',
      render: (record) => (
        <div>
          <Button
            type="default"
            className="primary-button"
            onClick={() => handleViewDetails(record)}
          >
            View
          </Button>
        </div>
      ),
    }
  ];
  const verifiedColumns: ColumnsType<any> = [...columns, ...additionalColumns];
  const onTabChange = async (tab: string) => {
    setActiveTab(tab);
    await getUsers(tab);
  };

  return (
    <>
      <div className="top-row justify-between mb-2 px-3 sm-w-48">
        <h2 className="text-xl font-bold m-0">Users</h2>
       <div className='flex justify-between'>
       <div className="fintech-user-tab">
          <Tabs
            size="middle"
            defaultActiveKey={activeTab}
            items={tabs}
            onChange={onTabChange}
          ></Tabs>
        </div>
        <div> 
          <Button className="mt-2" type='default' danger
           onClick={handleClearFilters}>
            Clear Filter
          </Button>
        </div>
       </div>
      </div>
      <div>
      <Table
      scroll={{ x: 1600 }}
      columns={activeTab === 'PENDING' ? pendingColumns : verifiedColumns}
      dataSource={userList}
      loading={isLoading}
      pagination={{
        responsive: true,
        showQuickJumper: true,
        showSizeChanger: true,
        current: pageNumber,
        pageSize: pageLimit,
        pageSizeOptions: [20, 30, 50, 100],
        total: count, 
        onChange: onPageChange,
      }}
/>

      </div>
      
    </>
  );
};

export default FintechUserList;
