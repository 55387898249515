import { Button, Form, FormInstance, Input, Radio, Spin } from 'antd';
import React, { ChangeEvent, Dispatch, FC, SetStateAction } from 'react';
import { ExcelToJson } from '../../../../../../../utils/Excel-Conversion/ExcelToJson';
import { ICancelOrdersForm } from '../../../../../../../interfaces/cancelOrders';
import { MdOutlineClose } from 'react-icons/md';
import Formlabel from '../../../../../../CommonComponents/FormLabel';

interface ICancelOrderFormProps {
  cancelOrderForm: FormInstance<ICancelOrdersForm>;
  onSubmit: (values: ICancelOrdersForm) => void;
  isOnlyCancel: boolean;
  setIsOnlyCancel: Dispatch<SetStateAction<boolean>>;
  onClickRemove: () => void;
  fileName: string;
  setFileName: Dispatch<SetStateAction<string>>;
  setOrdersFile: Dispatch<SetStateAction<string[] | null>>;
  ordersFile: string[] | null;
  fileInputRef: React.MutableRefObject<HTMLInputElement | null>;
  formLoading: boolean;
}

const CancelOrderForm: FC<ICancelOrderFormProps> = ({
  cancelOrderForm,
  onSubmit,
  isOnlyCancel,
  setIsOnlyCancel,
  onClickRemove,
  fileName,
  setFileName,
  setOrdersFile,
  ordersFile,
  fileInputRef,
  formLoading
}) => {
  const onFileChange = async (event: ChangeEvent<HTMLInputElement>) => {
    cancelOrderForm?.resetFields();

    if (event.target.files?.length) {
      setFileName(event.target.files[0]?.name);
      const result = await ExcelToJson({ dataFile: event?.target?.files[0] });
      const onlyValues: string[] = result.map((item) => Object.values(item)[0] as string);
      setOrdersFile(onlyValues);
    } else {
      setOrdersFile([]);
    }
  };

  return (
    <div className="flex justify-center">
      <div className="max-w-[550px] w-full p-5 primary-box-shadow rounded-x">
        <Spin spinning={formLoading}>
          <Form
            layout="vertical"
            scrollToFirstError
            id={'cancelOrderForm'}
            form={cancelOrderForm}
            onFinish={onSubmit}>
            <div className="flex flex-col gap-3">
              <Formlabel labelText="Orders Count :" required />

              <Form.Item
                name="cancelType"
                rules={[{ required: true, message: 'Please select cancel type.' }]}
                initialValue={isOnlyCancel}>
                <Radio.Group
                  className="py-2 flex"
                  onChange={({ target }) => setIsOnlyCancel(target?.value)}
                  value={isOnlyCancel}>
                  <Radio value={true}>One Order</Radio>
                  <Radio value={false}>Multiple Orders</Radio>
                </Radio.Group>
              </Form.Item>
            </div>

            {isOnlyCancel ? (
              <div className="flex flex-col gap-3">
                <Formlabel labelText="Enter Order ID :" required />
                <Form.Item
                  rules={[
                    {
                      required: true,
                      message: 'Please Enter Order ID!'
                    }
                  ]}
                  name={'cancelOrderID'}>
                  <Input
                    placeholder="Enter Order id"
                    className="w-full pl-1"
                    type="text"
                    id="clientIdInput"
                    suffix={false}
                  />
                </Form.Item>
              </div>
            ) : (
              <>
                <Formlabel labelText="Upload CSV/Excel (XLXS) File" required />

                <div className="flex flex-col md:flex-row items-center gap-2 mt-3">
                  <input
                    ref={fileInputRef}
                    className="w-[99px] pl-1"
                    type="file"
                    id="cancelOrdersFiles"
                    required
                    accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                    onChange={onFileChange}
                    onClick={onClickRemove}
                  />
                  <div title={fileName}>
                    {fileName.length > 20 ? fileName.slice(0, 20) + '...' : fileName}
                    {ordersFile && ordersFile?.length > 0 && (
                      <Button
                        onClick={onClickRemove}
                        title="Remove file"
                        className="border-none ml-2 bg-blue-100"
                        icon={<MdOutlineClose />}
                      />
                    )}
                  </div>
                </div>
              </>
            )}

            <div className="flex flex-col gap-3 mt-5">
              <Formlabel labelText="Reason :" required />
              <Form.Item
                name={'reason'}
                rules={[{ required: true, message: 'Please Enter Reason!' }]}>
                <Input.TextArea placeholder="Enter Reason" rows={5} maxLength={200} showCount />
              </Form.Item>
            </div>
          </Form>

          <div className="text-center mt-5">
            <Button htmlType="submit" form="cancelOrderForm" className="primary-button">
              Submit
            </Button>
          </div>
        </Spin>
      </div>
    </div>
  );
};

export default CancelOrderForm;
