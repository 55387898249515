import { Modal, Steps } from 'antd';
import React, { FC } from 'react';

interface IPaymentGatewayModal {
  isModalOpen: boolean;
  setIsModalOpen: (value: boolean) => void;
  detailsData: string[] | undefined;
}
const PaymentGatewayModal: FC<IPaymentGatewayModal> = ({
  isModalOpen,
  setIsModalOpen,
  detailsData
}) => {
  return (
    <Modal
      title="Payment Gateway Details"
      open={isModalOpen}
      footer={false}
      onCancel={() => setIsModalOpen(false)}>
      {detailsData?.length ? (
        <Steps
          progressDot
          current={detailsData?.length}
          direction="vertical"
          items={detailsData?.map((item) => ({
            title: item
          }))}
        />
      ) : (
        <div className="min-h-[150px] flex justify-center items-center text-gray-500 text-lg">
          No Details Found
        </div>
      )}
    </Modal>
  );
};

export default PaymentGatewayModal;
