import { Dispatch, SetStateAction } from 'react';
import { httpClient } from '../../../../../utils/apiRequest';
import { ErrorMessage } from '../../../../../utils/ResponseMessages';
import { IOrderLogsData } from '../../../../../interfaces/orderLogs';

export const getOrderLogs = async ({
  orderId,
  setIsLoading,
  setData
}: {
  orderId: string;
  setIsLoading: Dispatch<SetStateAction<boolean>>;
  setData: Dispatch<SetStateAction<IOrderLogsData | any>>;
}) => {
  setIsLoading(true);
  try {
    const response = await httpClient.post('/order/order-logs', {
      orderId
    });
    if (response?.data?.data) {
      setData(response?.data?.data[0]);
    }
  } catch (err: any) {
    setData(null);
    console.log('Error in getting order logs:', err);
    ErrorMessage(err?.response?.data?.message);
  } finally {
    setIsLoading(false);
  }
};
