import { Button, Form, Input, Spin, message, notification } from 'antd';
import React, { useRef, useState } from 'react';
import { MdOutlineClose } from 'react-icons/md';
import { uploadAcknowledgement } from '../api';

const UploadAcknowledgementFinx: React.FC = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [fileName, setFileName] = useState('');
  const [file, setFile] = useState<File | null>(null);
  const [bookingFrom] = Form.useForm();
  const fileInputRef = useRef<HTMLInputElement | null>(null); 

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      const uploadedFile = event.target.files[0];
      setFile(uploadedFile);
      setFileName(uploadedFile.name);
    }
  };

  const onClickRemove = () => {
    setFile(null);
    setFileName('');
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
  };
  const onClickSubmit = async () => {
    if (!file) {
      message.error('Please upload a file');
      return;
    }
    setIsLoading(true);
  
    const formData = new FormData();
    formData.append("file", file);
  
    try {
      const responseData = await uploadAcknowledgement(formData);
  
      console.log("API Response Data:", responseData);
  
      setIsLoading(false);
  
      if (responseData?.success) {
        message.success(responseData?.message || "File uploaded successfully");
      } else {
        if (responseData?.errors?.length > 0) {
          const errorMessages = responseData.errors
            .map((err: { orderId: any; message: any }) => `${err.orderId}: ${err.message}`)
            .join(", ");
          message.error(`Upload failed: ${errorMessages}`);
        } else {
          message.error(responseData?.message || "File upload failed");
        }
      }
    } catch (error) {
      console.error("Upload error:", error);
      setIsLoading(false);
      message.error("An unexpected error occurred. Please try again.");
    }
  };
  
  
  return (
    <>
      <div className="mb-4 flex items-center justify-between">
        <div className="flex items-center " style={{ gap: '10px' }}>
          <h2 className="text-xl font-normal m-0">Upload Acknowledgement</h2>
        </div>
      </div>

      <div className="flex flex-col items-center">
        <div className="my-5 w-[90%] md:w-[500px] primary-box-shadow rounded-xl">
          <Spin spinning={isLoading}>
            <div className="p-5 bg-white opacity-70 rounded-xl">
              <Form onFinish={onClickSubmit} id="bookingFrom" form={bookingFrom} layout="vertical">
                <div className="w-[70%]">
                  <Form.Item
                    rules={[
                      {
                        required: true,
                        message: 'Please upload a File!'
                      }
                    ]}
                    label="Upload CSV/Excel(XLXS) File"
                    name="uploadBooking">
                    <div className="flex flex-col md:flex-row items-center gap-2">
                      <Input
                        disabled={isLoading}
                        className="w-[95px] pl-1"
                        type="file"
                        id="bookingFile"
                        accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                        onChange={handleFileChange}
                      />
                      <div title={fileName}>
                        {fileName.length > 20 ? fileName.slice(0, 20) + '...' : fileName}
                        {file && (
                          <Button
                            onClick={onClickRemove}
                            title="Remove file"
                            className="border-none"
                            icon={<MdOutlineClose />}
                          />
                        )}
                      </div>           
                    </div>
                  </Form.Item>
                </div>

                <Button
                  disabled={!file}
                  className="mt-5 primary-button"
                  htmlType="submit"
                  form="bookingFrom">
                  Submit
                </Button>
              </Form>
            </div>
          </Spin>
        </div>
      </div>
    </>
  );
};

export default UploadAcknowledgementFinx;
