import React, { Dispatch, FC, SetStateAction, useEffect, useState } from 'react';
import { IZohoInvoiceDetails } from '../../../../interfaces/zohoInvoices';
import GetCopyTextDiv from '../../../CommonComponents/GetCopyTextDiv';
import moment from 'moment';
import GetStatus from '../../../../utils/GetStatus';
import { dateFormats } from '../../../../utils/constant';
import { Button, Divider, Modal, Steps, Tag, Tooltip } from 'antd';
import { FaArrowsAltH, FaCheckCircle, FaDownload, FaExternalLinkAlt } from 'react-icons/fa';
import { invoiceStatus } from '../constants';
import { CloseCircleFilled } from '@ant-design/icons';
import { MdClose } from 'react-icons/md';
import SuccessIcon from '../../../../assets/Icons/SuccessIcon';
import AmountDetailsModal from '../AmountDetailsModal';
import EmailDetailsModal from '../EmailDetailsModal';
import EmailFollowUpModal from '../EmailFollowUpModal';

interface IBasicDetails {
  detailsData: IZohoInvoiceDetails | null;
}

const BasicDetails: FC<IBasicDetails> = ({ detailsData }) => {
  const [journeyModal, setJourneyModal] = useState<boolean>(false);
  const [basicDetails, setBasicDetails] = useState<
    {
      key: number;
      label: string;
      data: JSX.Element;
    }[]
  >([]);

  const [amountDetailsModal, setAmountDetailsModal] = useState<boolean>(false);
  const [emailDetailsModal, setEmailDetailsModal] = useState<boolean>(false);
  const [emailFollowUpModal, setEmailFollowUpModal] = useState<boolean>(false);

  useEffect(() => {
    if (detailsData) {
      const updatedData = [
        {
          label: 'Client Id',
          data: <GetCopyTextDiv text={detailsData?.clientId} />
        },
        {
          label: 'User Id',
          data: <GetCopyTextDiv text={detailsData?.userId} />
        },
        {
          label: 'Date',
          data: (
            <div className="flex gap-2 items-center flex-wrap">
              <Tag className="m-0 font-semibold">
                {moment(detailsData?.from).format(dateFormats?.formatOne)}
              </Tag>
              <FaArrowsAltH />
              <Tag className="m-0 font-semibold">
                {moment(detailsData?.to).format(dateFormats?.formatOne)}
              </Tag>
            </div>
          )
        },
        {
          label: 'Place of Supply',
          data: <GetCopyTextDiv text={detailsData?.placeOfSupply} />
        },
        {
          label: 'GST Num',
          data: <GetCopyTextDiv text={detailsData?.gstNo} />
        },
        {
          label: 'Status',
          data: (
            <div className="flex gap-2 items-center">
              <GetStatus
                currStatus={detailsData?.status}
                PROCESSING={invoiceStatus?.INITIATED}
                SUCCESS={invoiceStatus?.APPROVED}
                FAILED={invoiceStatus?.REJECTED}
                COMPLETED={
                  detailsData?.status === invoiceStatus?.RAISED
                    ? invoiceStatus?.RAISED
                    : invoiceStatus?.EXECUTED
                }
                CUSTOM_LABEL={detailsData?.status?.toUpperCase()?.split('_')?.join(' ')}
                SHOW_TAGS
              />

              <Tooltip title="Invoice Journey">
                <Button
                  className="text-xs h-[28px]"
                  onClick={() => setJourneyModal(true)}
                  type="primary"
                  icon={<FaExternalLinkAlt />}>
                  {`( ${detailsData?.journey?.length} )`}
                </Button>
              </Tooltip>
            </div>
          )
        },
        {
          label: 'Invoice Url',
          data: detailsData?.invoiceUrl ? (
            <Tooltip title="Download">
              <Button
                onClick={() => window.open(detailsData?.invoiceUrl, '_blank')}
                icon={<FaDownload size={25} />}
                className="border-none"
              />
            </Tooltip>
          ) : (
            <p>---</p>
          )
        },
        {
          label: 'Fully Paid',
          data: detailsData.isPaid ? (
            <SuccessIcon />
          ) : (
            <MdClose className="text-red-500 border rounded-full border-red-500 " size="30" />
          )
        },
        {
          label: 'Created',
          data: <p>{moment(detailsData?.createdAt).format(dateFormats?.formatOne)}</p>
        },
        {
          label: 'Contact Category',
          data: <p className="capitalize">{detailsData?.contactCategory?.split('_')?.join(' ')}</p>
        },
        {
          label: 'Invoice Id',
          data: <GetCopyTextDiv text={detailsData?.invoiceId} />
        },
        {
          label: 'Invoice Number',
          data: <GetCopyTextDiv text={detailsData?.invoiceNumber} />
        },
        {
          label: 'Payment Terms Label',
          data: <GetCopyTextDiv text={detailsData?.paymentTermsLabel} />
        },
        {
          label: 'Tax Reg No',
          data: <GetCopyTextDiv text={detailsData?.taxRegNo} />
        },
        {
          label: 'Tax Treatment',
          data: <GetCopyTextDiv text={detailsData?.taxTreatment?.split('_')?.join(' ')} />
        },
        {
          label: 'Total Amount Received',
          data: <GetCopyTextDiv text={detailsData?.totalAmountReceived?.toString()} />
        }
      ];

      const appendKey = updatedData?.map((item, index) => ({
        ...item,
        key: index + 1
      }));

      setBasicDetails(appendKey);
    }
  }, [detailsData]);

  return (
    <>
      <div className="flex flex-col lg:flex-row gap-5">
        <div className="bg-white py-2 px-3 md:px-5 rounded shadow  w-full lg:w-1/2 flex flex-col gap-2">
          {basicDetails?.slice(0, 8)?.map((item) => (
            <div key={item?.key} className="flex gap-2 flex-wrap items-center">
              <p className="flex gap-1 sm:min-w-[170px] justify-between">
                <span>
                  <b>{item?.key}. </b>
                  {item?.label}
                </span>
                <span>:</span>
              </p>
              <div className="capitalize">{item?.data}</div>
            </div>
          ))}
        </div>

        <div className="bg-white py-2 px-3 md:px-5 rounded shadow  w-full lg:w-1/2 flex flex-col gap-2">
          {basicDetails?.slice(8)?.map((item) => (
            <div key={item?.key} className="flex gap-2 flex-wrap items-center">
              <p className="flex gap-1 sm:min-w-[180px] justify-between">
                <span>
                  <b>{item?.key}. </b>
                  {item?.label}
                </span>
                <span>:</span>
              </p>
              <div className="capitalize">{item?.data}</div>
            </div>
          ))}
        </div>
      </div>

      <Divider />
      <div className="flex gap-5">
        <Button type="primary" onClick={() => setAmountDetailsModal(true)}>
          Amount Details
        </Button>
        <Button type="primary" onClick={() => setEmailDetailsModal(true)}>
          Email Details
        </Button>
        <Button className="primary-button" onClick={() => setEmailFollowUpModal(true)}>
          Email Follow Up
        </Button>
      </div>

      <InvoiceJourney
        detailsData={detailsData}
        isModalOpen={journeyModal}
        setIsModalOpen={setJourneyModal}
      />

      <AmountDetailsModal
        isModalOpen={amountDetailsModal}
        setIsModalOpen={setAmountDetailsModal}
        detailsData={detailsData}
      />

      <EmailDetailsModal
        isModalOpen={emailDetailsModal}
        setIsModalOpen={setEmailDetailsModal}
        detailsData={detailsData}
      />

      <EmailFollowUpModal
        isModalOpen={emailFollowUpModal}
        setIsModalOpen={setEmailFollowUpModal}
        detailsData={detailsData}
      />
    </>
  );
};

export default BasicDetails;

interface IInvoiceJourney {
  detailsData: IZohoInvoiceDetails | null;
  isModalOpen: boolean;
  setIsModalOpen: Dispatch<SetStateAction<boolean>>;
}

const InvoiceJourney: FC<IInvoiceJourney> = ({ detailsData, isModalOpen, setIsModalOpen }) => {
  const onClose = () => {
    setIsModalOpen(false);
  };
  return (
    <>
      <Modal
        title={`Invoice Journey : ( ${detailsData?.journey?.length} )`}
        open={isModalOpen}
        footer={false}
        onCancel={onClose}>
        <Steps
          direction="vertical"
          size="small"
          progressDot
          current={detailsData?.journey?.length}
          className="gap-3 mt-5"
          items={detailsData?.journey?.map((item) => ({
            title: (
              <GetStatus
                currStatus={item?.action}
                PROCESSING={invoiceStatus?.INITIATED}
                SUCCESS={invoiceStatus?.APPROVED}
                FAILED={invoiceStatus?.REJECTED}
                COMPLETED={
                  detailsData?.status === invoiceStatus?.RAISED
                    ? invoiceStatus?.RAISED
                    : invoiceStatus?.EXECUTED
                }
                CUSTOM_LABEL={item?.action?.toUpperCase()?.split('_')?.join(' ')}
                SHOW_TAGS
              />
            ),
            description: (
              <div className="max-w-[500px] mt-1">
                <div className="flex gap-2 flex-wrap">
                  <p className="flex gap-1 sm:min-w-[100px] justify-between">
                    <span>Action By</span>
                    <span>:</span>
                  </p>
                  <div className="capitalize">
                    <GetCopyTextDiv text={item?.actionBy} />
                  </div>
                </div>

                <div className="flex gap-2 flex-wrap">
                  <p className="flex gap-1 sm:min-w-[100px] justify-between">
                    <span>Date</span>
                    <span>:</span>
                  </p>
                  <div className="capitalize">
                    {moment(item?.createdAt).format(dateFormats?.formatOne)}
                  </div>
                </div>
              </div>
            )
          }))}
        />
      </Modal>
    </>
  );
};
