import { Button, DatePicker, Form, Input, Modal, Select, Spin } from 'antd';
import React, { Dispatch, FC, SetStateAction, useEffect, useState } from 'react';
import Formlabel from '../../../CommonComponents/FormLabel';
import { IStateList } from '../../../../interfaces/addressApis';
import { getStatesList } from '../../../../hooks/addressAPIs';
import dayjs, { Dayjs } from 'dayjs';
import { invoiceFormValidations } from '../../ZohoInvoices/constants';
import { IInitiateInvoiceForm } from '../../../../interfaces/invoiceActions';
import { initiateInvoice } from '../api';

interface IInitiateInvoiceModal {
  clientId: string | undefined;
  gstNo: string | undefined;
  isModalOpen: boolean;
  setIsModalOpen: Dispatch<SetStateAction<boolean>>;
  getInvoiceList: () => void;
}

const InitiateInvoiceModal: FC<IInitiateInvoiceModal> = ({
  clientId,
  gstNo,
  isModalOpen,
  setIsModalOpen,
  getInvoiceList
}) => {
  const [initiateInvoiceForm] = Form.useForm<IInitiateInvoiceForm>();
  const [statesData, setStatesData] = useState<IStateList[]>([]);
  const [isModalLoading, setIsModalLoading] = useState<boolean>(false);

  useEffect(() => {
    if (isModalOpen && !statesData?.length) {
      handleGetStates();
    }

    if (clientId) {
      initiateInvoiceForm.setFieldValue('clientId', clientId);
      initiateInvoiceForm.setFieldValue('gstNo', gstNo);
    }

    if (gstNo) {
      initiateInvoiceForm.setFieldValue('gstNo', gstNo);
    }
  }, [isModalOpen]);

  const handleGetStates = async () => {
    await getStatesList({
      setData: setStatesData,
      countryCode: 'IN',
      setLoading: setIsModalLoading
    });
  };

  const onClose = () => {
    setIsModalOpen(false);
    initiateInvoiceForm.resetFields();
  };

  const disabledDate = (current: Dayjs) => {
    return current && current > dayjs().endOf('day');
  };

  const onSubmit = async (formValues: IInitiateInvoiceForm) => {
    const payload = {
      placeOfSupply: formValues?.placeOfSupply,
      gstNo: formValues?.gstNo,
      from: dayjs(formValues?.dateRange[0]).format('YYYY-MM-DD'),
      to: dayjs(formValues?.dateRange[1]).format('YYYY-MM-DD'),
      clientId: formValues?.clientId,
      invoiceDate: dayjs(formValues?.invoiceDate).format('YYYY-MM-DD')
    };

    await initiateInvoice({
      setLoading: setIsModalLoading,
      payload,
      callBack: () => {
        onClose();
        getInvoiceList();
      }
    });
  };

  return (
    <Modal
      open={isModalOpen}
      onCancel={onClose}
      footer={false}
      title={`Initiate Invoice ( ${clientId} )`}>
      <Spin spinning={isModalLoading}>
        <Form
          onFinish={onSubmit}
          form={initiateInvoiceForm}
          scrollToFirstError
          id="initiateInvoiceForm">
          <div className="flex flex-col gap-2">
            <Formlabel labelText="Client Id:" required />
            <Form.Item
              name="clientId"
              rules={[{ required: true, message: invoiceFormValidations?.clientId?.required }]}>
              <Input disabled placeholder="Enter Client Id" />
            </Form.Item>
          </div>

          <div>
            <Formlabel labelText="GST Number" required />
            <Form.Item
              name="gstNo"
              className="mt-1"
              rules={[
                {
                  required: true,
                  message: invoiceFormValidations?.gstNo?.required
                },
                {
                  pattern: invoiceFormValidations?.gstNo?.patternValueRegex,
                  message: invoiceFormValidations?.gstNo?.patternMessage
                }
              ]}>
              <Input disabled placeholder="Enter gst number" />
            </Form.Item>
          </div>

          <div>
            <Formlabel labelText="Invoice Date" required />
            <Form.Item
              name="invoiceDate"
              className="mt-1"
              rules={[{ required: true, message: invoiceFormValidations?.date?.required }]}>
              <DatePicker
                disabledDate={disabledDate}
                className="w-full"
                id="invoiceDate"
                format="YYYY-MM-DD"
              />
            </Form.Item>
          </div>

          <div>
            <Formlabel labelText="Select Date Range" required />
            <Form.Item
              name="dateRange"
              className="mt-1"
              rules={[{ required: true, message: invoiceFormValidations?.date?.required }]}>
              <DatePicker.RangePicker
                className="w-full"
                disabledDate={disabledDate}
                format="YYYY-MM-DD"
              />
            </Form.Item>
          </div>

          <div>
            <Formlabel labelText="Place Of Supply" required />
            <Form.Item
              className="mt-1"
              name="placeOfSupply"
              rules={[
                { required: true, message: invoiceFormValidations?.placeOfSupply?.required }
              ]}>
              <Select
                showSearch
                disabled={!statesData?.length}
                placeholder="Select any state"
                optionFilterProp="label"
                options={statesData?.map((item) => ({
                  label: `${item?.name} - ${item?.code}`,
                  value: item?.code
                }))}
              />
            </Form.Item>
          </div>
        </Form>

        <div className="text-center">
          <Button className="primary-button w-[120px]" form="initiateInvoiceForm" htmlType="submit">
            Submit
          </Button>
        </div>
      </Spin>
    </Modal>
  );
};

export default InitiateInvoiceModal;
