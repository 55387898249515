import React from 'react';
import { ColumnsType } from 'antd/es/table';
import moment from 'moment';
import { Button, Tag } from 'antd';
import { FaDownload } from 'react-icons/fa';
import { ICartListAddressDetails, ICartListPackageDetails, ICartListResponse } from '../../../../interfaces/cartList';

export const TableColumns = () => {
    const formatServiceType = (type: string) => {
        const serviceTypeMap: Record<string, string> = {
          REGISTERED_POST: 'Registered Post',
          SELF_DROP: 'Self Drop',
          ORDINARY_POST: 'Ordinary Post',
          RPAD:'rpad'
        };
      
        return serviceTypeMap[type] || type; 
      };
      
      const formatServiceProvider = (provider: string) => {
        const serviceProviderMap: Record<string, string> = {
          INDIA_POST: 'India Post',
        };
      
        return serviceProviderMap[provider] || provider; 
      };
      
      
  const cartTableColumns: ColumnsType<ICartListResponse> = [
    {
      title: 'Order Id',
      dataIndex: 'orderId',
      key: 'orderId'
    },
    {
      title: 'Order Type',
      dataIndex: 'orderType',
      key: 'orderType',
      responsive: ['sm'],
      render: (text,record) =>(<h3>
        <Tag color="green"> {record?.orderType}</Tag>
      </h3> )
    },
    {
      title: 'Date',
      dataIndex: 'createdAt',
      render: (date) => <p className="w-[120px]">{moment(date).format('DD-MM-YYYY hh:mm:ss')}</p>,
    },
    {
        title: (
          <div>
            Serial <br />
            Number
          </div>
        ),
        dataIndex: 'serialNumber',
        key: 'serialNumber',
       
      },
    {
      title: 'Cart Status',
      key: 'cartStatus',
      dataIndex: 'cartStatus'
    },
    {
      title: (
        <div className="text-center">
          Dimensions (cm) & Weight (g)
        </div>
      ),
      dataIndex: 'packageDetails',
      render: (packageDetails) => {
        console.log("Package Details:", packageDetails);
        if (!Array.isArray(packageDetails) || packageDetails.length === 0) {
          return <div className="text-center">N/A</div>;
        }
        const { length = 0, width = 0, height = 0, weight = 0 } = packageDetails[0];
    
        return (
          <div className="text-center">
            <p>Dimensions: {length} x {width} x {height} cm</p>
            <p>Weight: {weight} g</p>
          </div>
        );
      },
    },
    {
        title: 'Service Type',
        dataIndex: 'serviceType',
        key: 'serviceType',
        render: (type) => <span>{formatServiceType(type)}</span>,
    },
    { 
        title: 'Service Provider',
        dataIndex: 'serviceProvider',
        key: 'serviceProvider',
        render: (provider) => <span>{formatServiceProvider(provider)}</span>,
    },
    {
      title: 'Document',
      key: 'shipmentLink',
      dataIndex: 'shipmentLink',
      fixed: 'right',
      render: (link) => (
        <Button
          disabled={!link?.length}
          icon={<FaDownload />}
          href={link}
          target="_blank"
          className="primary-button">
          Download
        </Button>
      )
    },
   
  ];

  const addressDetailsColumns: ColumnsType<ICartListAddressDetails> = [
    {
      title: '',
      dataIndex: 'rowHeading',
      fixed: 'left',
      render: (data) => (data ? <p className="m-0 font-semibold">{data}</p> : '-')
    },
    {
      title: 'Customer Name',
      dataIndex: 'name',
      responsive: ['sm'],
      render: (data) => data ?? 'NA'
    },
    {
      title: 'Address line 1',
      dataIndex: 'line1',
      responsive: ['sm'],
      render: (data) => data ?? 'NA'
    },
    {
      title: 'Address line 2',
      dataIndex: 'line2',
      responsive: ['sm'],
      render: (data) => data ?? 'NA'
    },
    {
      title: 'City',
      dataIndex: 'city',
      responsive: ['sm'],
      render: (data) => data ?? 'NA'
    },
    {
      title: 'State',
      dataIndex: 'state',
      responsive: ['sm'],
      render: (state) => state ?? 'NA'
    },
    {
      title: 'Country',
      dataIndex: 'country',
      responsive: ['sm'],
      render: (country) => country ?? 'NA'
    },
    {
      title: (
        <div className="text-center">
          Pin <br />
          Code
        </div>
      ),
      dataIndex: 'pinCode',
      responsive: ['sm'],
      render: (data) => data ?? 'NA'
    },
    {
      title: 'Mobile Num',
      dataIndex: 'mobile',
      responsive: ['sm'],
      render: (phone) => (phone ?? 'NA')
    },
    {
      title: 'Alternate Mobile',
      dataIndex: 'alternateMobile',
      responsive: ['sm'],
      render: (phone) => (phone ?? 'NA')
    },
    {
      title: 'Email Id',
      dataIndex: 'email',
      responsive: ['sm'],
      render: (data) => (data ? data : 'NA')
    }
  ];

  const packageDetailsColumns: ColumnsType<ICartListPackageDetails> = [
    {
        title: (
          <div className="text-center">
            Package <br /> Num
          </div>
        ),
        dataIndex: 'packageDetails',
        fixed: 'left',
        render: (packageNumber) => packageNumber?.length ?? 'NA'
      },
      {
        title: 'Category',
        dataIndex: 'itemCategory',
        render: (category) => category ?? 'NA'
      },
      {
        title: 'Description',
        dataIndex: 'itemDescription',
        render: (description) => description ?? 'NA'
      },
      {
        title: 'Estimated Value',
        dataIndex: 'estimatedValue',
        render: (value) => (value ? `₹ ${value}` : 'NA')
      },
      {
        title: 'Weight (g)',
        dataIndex: 'weight',
      },
      {
        title: 'Length (cm)',
        dataIndex: 'length',
      },
      {
        title: 'Height (cm)',
        dataIndex: 'height',
      },
      {
        title: 'Width (cm)',
        dataIndex: 'width',
      }
    ];
  return { cartTableColumns, addressDetailsColumns, packageDetailsColumns };
};
