import { Button, Tabs, Spin, PaginationProps } from 'antd';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { getbulkList, getUserDetails } from '../api';
import { ReloadOutlined } from '@ant-design/icons';
import HandleBulkOrderList from './HandleBulkOrderList';

const BulkOrderlist: React.FC = () => {
  const { userId } = useParams<{ userId: string }>();
  const navigate = useNavigate();
  const [userData, setUserData] = useState<any>(null);
  const [bulkList, setBulkList] = useState<any[]>([]);
  const [activeTab, setActiveTab] = useState<string>('Open To Ship');
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [pageLimit, setPageLimit] = useState<number>(20);
  const [bulkListLoading, setBulkListLoading] = useState<boolean>(false);
  const [selectedRaws, setSelectedRaws] = useState<any[]>([]);
  const [filters, setFilters] = useState({});
  const [hasError, setHasError] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [status, setstatus] = useState("BULK_INITIATE"); 
  const [count, setCount] = useState(0); 

  useEffect(() => {
    if (userId) {
      fetchUserDetails();
      fetchBulkOrders(status);
    }
  }, [userId]);

  const fetchUserDetails = async () => {
    if (userId) {
      try {
        const response = await getUserDetails(userId);
        setUserData(response.userDetail);
      } catch (error) {
        console.error(error);
        setUserData({ name: "Unknown User", email: "Unknown Email" });
      }
    }
  };

  const clearFilters = async () => {
    setFilters({});
    setPageNumber(1);    
    try {
      await getBulk(status, true); 
    } catch (error) {
      console.error("Error during clearFilters:", error);
    }
  };
  
  useEffect(() => {
    if (userId) {
      fetchUserDetails();
      refreshData();
    }
  }, [userId]);

  const getStatusFromTab = (tabName: string) => {
    const statusMapping: Record<string, string> = {
      "Open To Ship To Ship": "BULK_INITIATE",
      "In-Process": "PROCESSING",
      "Shipment Placed": "SHIPPED"
    };
    return statusMapping[tabName] || "BULK_INITIATE";
  };
  
  const getBulk = async (
    status = getStatusFromTab(activeTab),
    resetFilters = false,
    bulkId?: string,
    serviceType?: string,
    serviceProvider?:string,
    pageNo = pageNumber, limit = pageLimit, user = userId,
    onPageChange?:(pageNo?:number,limit?:number) => void
  ) => {
    const params: any = resetFilters
      ? {pageNumber: pageNo, pageLimit: limit, userId: user , status }
      : { ...filters,pageNumber: pageNo, pageLimit: limit, userId: user ,status };
  
    if (bulkId) params.bulkId = bulkId;
    if (serviceType) params.serviceType = serviceType;
    if(serviceProvider)params.serviceProvider = serviceProvider;
    try {
      setBulkListLoading(true);
      setHasError(false);
      const bulkListApiRes = await getbulkList(params);
      setBulkList(bulkListApiRes.list || []);
      setCount(bulkListApiRes.count);
        if (onPageChange) {
          onPageChange(pageNo, limit);
        }
    } catch (error: any) {
      setBulkList([]);
      setHasError(true);
    } finally {
      setBulkListLoading(false);
    }
  };
  
  const onPageChange: PaginationProps["onChange"] = (pageNo, limit) => {;
    setPageNumber(pageNo);
    setPageLimit(limit);
    getBulk(status, false, undefined, undefined,undefined, pageNo, limit);
  };

  useEffect(() => {
    const mappedStatus = getStatusFromTab(activeTab);
    getBulk(mappedStatus, false, undefined, undefined, undefined, pageNumber, pageLimit);
  }, [activeTab, pageNumber, pageLimit]);
  
  const refreshData = () => {
    fetchBulkOrders(status);
  };

  const fetchBulkOrders = async (status: string) => {
    try {
        setBulkListLoading(true);
        setHasError(false);

        const params = { pageNumber: currentPage, pageLimit, userId, status: status };
        const bulkListApiRes = await getbulkList(params);
        if (!bulkListApiRes || !bulkListApiRes.list) {
            console.error("Empty response received from API");
            setBulkList([]);
        } else {
            setBulkList(bulkListApiRes.list);
        }

    } catch (error: any) {
        console.error("Error fetching bulk orders:", error);
        setHasError(true);
        setBulkList([]);
    } finally {
        setBulkListLoading(false);
    }
};

  useEffect(() => {
    if (status) {
      fetchBulkOrders(status);  
    }
  }, [status]);
  
  const onTabChange = (tab: string) => {
    let newBulkStatus = "";
  
    if (tab === "Open To Ship") newBulkStatus = "BULK_INITIATE";
    if (tab === "In-Process") newBulkStatus = "PROCESSING";
    if (tab === "Shipment Placed") newBulkStatus = "SHIPPED";
  
    setActiveTab(tab);
    setstatus(newBulkStatus); 
    setCurrentPage(1);
  };
  useEffect(() => {
    if (status) {
      fetchBulkOrders(status);
    }
  }, [status]);
  
  const tabs = [
    {
      key: 'Open To Ship',
      label: 'Open To Ship',
      children: (
        <HandleBulkOrderList
          currentPortalName="Ship To"
          bulkOrderList={bulkList}
          setActiveTab={setActiveTab}
          activeKey={activeTab}
          setSelectedRaws={setSelectedRaws}
          isLoading={bulkListLoading}
          setIsLoading={setIsLoading}
          fetchBulkOrders={getBulk} 
          getBulk={getBulk}
          status={status} 
          onPageChange={onPageChange}
          count={count}
          pageLimit={pageLimit}
          pageNumber={pageNumber}
          activeTab={activeTab}
          />
      ),
    },
    {
      key: 'In-Process',
      label: 'In-Process',
      children: (
        <HandleBulkOrderList
          currentPortalName="In-Process"
          bulkOrderList={bulkList}
          setActiveTab={setActiveTab}
          activeKey={activeTab}
          setSelectedRaws={setSelectedRaws}
          isLoading={bulkListLoading}
          setIsLoading={setIsLoading}
          fetchBulkOrders={getBulk}  
          getBulk={getBulk} 
          status={status} 
          onPageChange={onPageChange}
          count={count}
          pageLimit={pageLimit}
          pageNumber={pageNumber}
          activeTab={activeTab}
            />
      ),
    },
    {
      key: 'Shipment Placed',
      label: 'Shipment Placed',
      children: (
        <HandleBulkOrderList
          currentPortalName="Ship"
          bulkOrderList={bulkList}
          setActiveTab={setActiveTab}
          activeKey={activeTab}
          setSelectedRaws={setSelectedRaws}
          isLoading={bulkListLoading}
          setIsLoading={setIsLoading}
          fetchBulkOrders={getBulk} 
          getBulk={getBulk}
          status={status} 
          onPageChange={onPageChange}
          count={count}
          pageLimit={pageLimit}
          pageNumber={pageNumber}
          activeTab={activeTab}
          />
      ),
    },
  ];
  return (
    <div>
      <Button type="link" onClick={() => navigate("/userlist-finx")}>
        {"<"}
        <span className="hover:underline pl-1">Back to User List</span>
      </Button>
      <div className="mb-4 flex items-center justify-between">
        <div className='flex items-center' style={{ gap: '10px' }}>
          <h2 className="text-xl font-normal m-0">
            Bulk Order List for:{" "}
            {userData && userData.firstName ? (
                  `${userData.firstName.trim().charAt(0).toUpperCase()}${userData.firstName.trim().slice(1).toLowerCase()}`
            ) : (
              "Loading..."
            )}
          </h2>
        </div>
        <div className="flex items-center">
          <Button size="large" onClick={refreshData} className="mr-2" icon={<ReloadOutlined className="" />}>
            Refresh
          </Button>
          <Button size="large" onClick={clearFilters} className="ml-2"  type='default' danger>
            Clear Filters
          </Button>
        </div>
      </div>

      <div className='fintech-user-tab'>
        <Tabs size='middle' activeKey={activeTab} onChange={onTabChange} items={tabs} />
      </div>
    </div>
    
  );
};

export default BulkOrderlist;
