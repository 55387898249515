import React, { useEffect, useState, useRef } from "react";
import { Button, Space, Table, Tag, Input, PaginationProps, Spin, message } from "antd";
import { FilePdfFilled, LoadingOutlined, ReloadOutlined, SearchOutlined, SnippetsFilled } from "@ant-design/icons";
import { getInvoice, getLable, getOrder } from "../api";
import { ColumnsType } from "antd/es/table";
import GetCopyTextDiv from "../../../CommonComponents/GetCopyTextDiv";
import Paragraph from "antd/es/typography/Paragraph";
import OrderDrawerFinx from "./OrderDrawer";

const Order: React.FC = () => {
  const [orderListLoading, setOrderListLoading] = useState<boolean>(false);
  const [orderData, setOrderData] = useState<any[]>([]);
  const [filteredOrderData, setFilteredOrderData] = useState<any[]>([]);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [pageLimit, setPageLimit] = useState<number>(20);
  const [searchBulkId, setSearchBulkId] = useState<string>("");
  const [showBulkIdSearch, setShowBulkIdSearch] = useState<boolean>(false);
  const [searchOrderId, setSearchOrderId] = useState<string>("");
  const [showOrderIdSearch, setShowOrderIdSearch] = useState<boolean>(false);
  const [searchUserId, setSearchUserId] = useState<string>("");
  const [showUserIdSearch, setShowUserIdSearch] = useState<boolean>(false);
  const [selectedOrderId, setSelectedOrderId] = useState<string | null>(null);
  const [drawerVisible, setDrawerVisible] = useState<boolean>(false);
  const [filters, setFilters] = useState({});
  const [count, setCount] = useState(0);
  const [processingOrderId, setProcessingOrderId] = useState<string | null>(null);
  const [orderList, setOrderList] = useState<any[]>([]);
  useEffect(() => {
    getOrderList();
  }, []);
  const orderSearchRef = useRef<HTMLDivElement>(null);
  const bulkSearchRef = useRef<HTMLDivElement>(null);
  const userSearchRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    getOrderList();
  }, []);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        orderSearchRef.current && !orderSearchRef.current.contains(event.target as Node) &&
        bulkSearchRef.current && !bulkSearchRef.current.contains(event.target as Node) &&
        userSearchRef.current && !userSearchRef.current.contains(event.target as Node)
      ) {
        setShowBulkIdSearch(false);
        setShowOrderIdSearch(false);
        setShowUserIdSearch(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  const getOrderList = async (pageNo = pageNumber, limit = pageLimit) => {
    try {
      setOrderListLoading(true);

      const orderListApiRes = await getOrder({ pageNumber: pageNo, pageLimit: limit });
      console.log(orderListApiRes, "API Response");

      setOrderData(orderListApiRes.list || []);
      setFilteredOrderData(orderListApiRes.list);

      setCount(orderListApiRes.totalCount);
    } catch (error) {
      console.error("Error fetching order data:", error);
    } finally {
      setOrderListLoading(false);
    }
  };

  const onPageChange: PaginationProps['onChange'] = (pageNo, limit) => {
    setPageNumber(pageNo);
    setPageLimit(limit);
    setTimeout(() => {
      getOrderList(pageNo, limit);
    }, 0);
  };


  const refreshData = async () => {
    await getOrderList();
  };

  const handleSearchByBulkId = async () => {
    if (!searchBulkId?.trim()) return;
    setOrderListLoading(true);

    try {
      const orderListApiRes = await getOrder({
        pageNumber: 1,
        pageLimit,
        bulkOrderId: searchBulkId.trim()
      })

      setOrderData(orderListApiRes.list || []);
      setFilteredOrderData(orderListApiRes.list || []);
    } catch (error) {
      console.error("Error fetching filtered bulk data:", error);
    }
    finally {
      setOrderListLoading(false);
      setShowBulkIdSearch(false);
    }
  };

  const handleSearchByOrderId = async () => {
    if (!searchOrderId?.trim()) return;

    setOrderListLoading(true);

    try {
      const orderListApiRes = await getOrder({
        pageNumber: 1,
        pageLimit,
        orderId: searchOrderId.trim()
      });

      setOrderData(orderListApiRes.list || []);
      setFilteredOrderData(orderListApiRes.list || []);
    } catch (error) {
      console.error("Error fetching filtered order data:", error);
    } finally {
      setOrderListLoading(false);
      setShowOrderIdSearch(false);
    }
  };

  const handleSearchByUserId = async () => {
    if (!searchUserId?.trim()) return;
    setOrderListLoading(true);

    try {
      const orderListApiRes = await getOrder({
        pageNumber: 1,
        pageLimit,
        userId: searchUserId.trim()
      })

      setOrderData(orderListApiRes.list || []);
      setFilteredOrderData(orderListApiRes.list || []);
    } catch (error) {
      console.error("Error fetching filtered user data:", error);
    }
    finally {
      setOrderListLoading(false);
      setShowUserIdSearch(false);
    }
  };

  const clearFilters = async () => {
    setFilters({});
    setSearchOrderId('');
    setSearchBulkId('');
    setSearchUserId('');
    try {
      await getOrderList();
    } catch (error) {
      console.error("Error during clearFilters:", error);
    }
  };

  const handleOrderIdClick = (orderId: string) => {
    setSelectedOrderId(orderId);
    setDrawerVisible(true);
  };

  const columns: ColumnsType<any> = [
    // orderId
    {
      title: (
        <div className="relative" ref={orderSearchRef}>
          <div className="flex items-center">
            Order ID
            <SearchOutlined
              className="ml-2 text-gray-500 cursor-pointer"
              onClick={() => setShowOrderIdSearch(!showOrderIdSearch)}
            />
          </div>
          {showOrderIdSearch && (
            <div className="absolute z-10 mt-2 bg-white w-50 p-3 shadow-lg border rounded">
              <Input
                autoFocus
                style={{ width: "170px" }}
                placeholder="Search by Order ID"
                value={searchOrderId}
                onChange={(e) => setSearchOrderId(e.target.value)}
                onPressEnter={handleSearchByOrderId}
                allowClear
              />
              <div className="mt-2 flex justify-end">
                <Button size="small" onClick={() => setShowOrderIdSearch(false)} className="me-5">
                  Cancel
                </Button>
                <Button type="primary" size="small" onClick={handleSearchByOrderId}>
                  Search
                </Button>
              </div>
            </div>
          )}
        </div>
      ),
      dataIndex: "orderId",
      key: "orderId",
      responsive: ["sm"],

      render: (orderId) => (
        <Paragraph
          copyable={{ text: orderId }}
          onClick={() => handleOrderIdClick(orderId)}
          style={{ color: "#1677ff", textDecoration: "underline", cursor: "pointer" }}
        >
          <b>{orderId ? orderId : 'NA'}</b>
        </Paragraph>
      ),
    },
    // bulkId
    {
      title: (
        <div className="relative" ref={bulkSearchRef}>
          <div className="flex items-center">
            Bulk ID
            <SearchOutlined
              className="ml-2 text-gray-500 cursor-pointer"
              onClick={() => setShowBulkIdSearch(!showBulkIdSearch)}
            />
          </div>
          {showBulkIdSearch && (
            <div className="absolute z-10 mt-2 bg-white w-50 p-3 shadow-lg border rounded">
              <Input
                autoFocus
                style={{ width: "170px" }}
                placeholder="Search by Bulk ID"
                value={searchBulkId}
                onChange={(e) => setSearchBulkId(e.target.value)}
                onPressEnter={handleSearchByBulkId}
                allowClear
              />
              <div className="mt-2 flex justify-end">
                <Button size="small" onClick={() => setShowBulkIdSearch(false)} className="me-5">
                  Cancel
                </Button>
                <Button type="primary" size="small" onClick={handleSearchByBulkId}>
                  Search
                </Button>
              </div>
            </div>
          )}
        </div>
      ),
      dataIndex: "bulkOrderId",
      key: "bulkOrderId",
      responsive: ["sm"],
      align: "center",
      render: (id) => <GetCopyTextDiv text={id} />,
    },
    // userId
    {
      title: (
        <div className="relative" ref={userSearchRef}>
          <div className="flex items-center">
            User ID
            <SearchOutlined
              className="ml-2 text-gray-500 cursor-pointer"
              onClick={() => setShowUserIdSearch(!showUserIdSearch)}
            />
          </div>
          {showUserIdSearch && (
            <div className="absolute z-10 mt-2 bg-white w-50 p-3 shadow-lg border rounded">
              <Input
                autoFocus
                style={{ width: "170px" }}
                placeholder="Search by User ID"
                value={searchUserId}
                onChange={(e) => setSearchUserId(e.target.value)}
                onPressEnter={handleSearchByUserId}
                allowClear
              />
              <div className="mt-2 flex justify-end">
                <Button size="small" onClick={() => setShowUserIdSearch(false)} className="me-5">
                  Cancel
                </Button>
                <Button type="primary" size="small" onClick={handleSearchByUserId}>
                  Search
                </Button>
              </div>
            </div>
          )}
        </div>
      ),
      dataIndex: "userId",
      key: "userId",
      responsive: ["sm"],
      align: "center",
      render: (id) => <GetCopyTextDiv text={id} />,
    },
    {
      title: "#AWB",
      dataIndex: "trackingNumber",
      key: "trackingNumber",
      responsive: ["sm"],
      render: (value) => <GetCopyTextDiv text={value} />,
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      align: "center",
      render: (text, record) =>
        record?.status === "FAILED" ? (
          <Tag color="red">{record?.status}</Tag>
        ) : (
          <Tag color="green">{record?.status}</Tag>
        ),
    },
    {
      title: "Shipping Charges",
      dataIndex: "netShippingCharge",
      key: "netShippingCharge",
      responsive: ["sm"],
      align: "center",
      render: (value) => <h3>{value}</h3>,
    },
    {
      title: "COD Charges",
      dataIndex: "netCodCharge",
      key: "netCodCharge",
      responsive: ["sm"],
      align: "center",
      render: (value) => <h3>{value}</h3>,
    },
    {
      title: "GST",
      dataIndex: "gst",
      key: "gst",
      responsive: ["sm"],
      render: (value) => <h3>{value}</h3>,
    },
    {
      title: "Total",
      dataIndex: "grossTotal",
      key: "grossTotal",
      responsive: ["sm"],
      align: "center",
      render: (value) => <h3>{value}</h3>,
    },
    {
      title: "Order Date",
      dataIndex: "createdAt",
      key: "createdAt",
      align: "center",
      render: (date) => new Date(date).toLocaleString(),
    },
    // label
    {
      title: 'Label (PDF)',
      key: 'label',
      width: 190,
      align: 'center',
      render: (record) => {
        const handleGenerateLabel = async () => {
          try {
            setProcessingOrderId(record.orderId);
            record.label = "PROCESSING";
            setOrderList([...orderList]);

            const response = await getLable({ orderId: record.orderId });

            if (response?.label) {
              record.label = response.label;
              setOrderList([...orderList]);
              window.open(response.label, "_blank");
            } else {
              record.label = null;
              setOrderList([...orderList]);
            }

            await getOrderList();
          } catch (error) {
            console.error("Error generating label:", error);
          } finally {
            setProcessingOrderId(null);
          }
        };

        if (!record.label) {
          return (
            <Button
              icon={<SnippetsFilled />}
              className="me-2"
              onClick={handleGenerateLabel}
            >
              Generate
            </Button>
          );
        }
        if (record.label === "PROCESSING") {
          return (
            <Button>
              In Process <Spin indicator={<LoadingOutlined spin />} size="small" />
            </Button>
          );
        }
        return (
          <>
            <Button
              icon={<SnippetsFilled />}
              className="primary-button me-1"
              href={record.label}
              target="_blank"
            >
              Label
            </Button>
            <Button onClick={handleGenerateLabel} icon={<ReloadOutlined />} />
          </>
        );
      },
    },
    // Invoice
    {
      title: 'Invoice',
      key: 'invoice',
      width: 200,
      align: 'center',
      render: (record) => {
        const handleGenerateInvoice = async () => {
          try {
            setProcessingOrderId(record.orderId);
            record.invoice = "PROCESSING";
            setOrderList([...orderList]);

            const response = await getInvoice({ orderId: record.orderId });

            if (response?.invoice) {
              record.invoice = response.invoice;
              setOrderList([...orderList]);
              window.open(response.invoice, "_blank");
            } else {
              record.invoice = null;
              setOrderList([...orderList]);
            }

            await getOrderList();
          } catch (error) {
            console.error("Error generating invoice:", error);
          } finally {
            setProcessingOrderId(null);
          }
        };
        if (!record.invoice) {
          return (
            <Button
              icon={<SnippetsFilled />}
              className="me-2"
              onClick={handleGenerateInvoice}
            >
              Generate
            </Button>
          );
        }
        if (record.invoice === "PROCESSING") {
          return (
            <Button>
              In Process <Spin indicator={<LoadingOutlined spin />} size="small" />
            </Button>
          );
        }
        return (
          <>
            <Button
              icon={<SnippetsFilled />}
              className="primary-button me-1"
              href={record.invoice}
              target="_blank"
            >
              Invoice
            </Button>
            <Button onClick={handleGenerateInvoice} icon={<ReloadOutlined />} />
          </>
        );
      },
    }
  ];
  return (
    <div>
      <div className="mb-4 flex items-center justify-between">
        <h2 className="text-xl font-normal m-0">Orders List</h2>
        <div className="flex items-center">
          <Button size="large" onClick={refreshData} className="mr-2" icon={<ReloadOutlined className="" />}>
            Refresh
          </Button>
          <Button size="large" onClick={clearFilters} className="ml-2" type='default' danger>
            Clear Filters
          </Button>
        </div>
      </div>
      <Table
        columns={columns}
        dataSource={filteredOrderData}
        loading={orderListLoading}
        rowKey={(record) => record.orderId}
        pagination={{
          responsive: true,
          showQuickJumper: true,
          showSizeChanger: true,
          current: pageNumber,
          pageSize: pageLimit,
          pageSizeOptions: [20, 30, 50, 100],
          total: count,
          onChange: onPageChange,
        }}
      />
      {selectedOrderId && (
        <OrderDrawerFinx
          visible={drawerVisible}
          orderId={selectedOrderId}
          setDrawerVisible={setDrawerVisible}
        />
      )}
    </div>
  );
};

export default Order;
