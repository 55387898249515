import { Button, Form, PaginationProps, Table, Tag, Tooltip } from 'antd';
import { ColumnsType } from 'antd/es/table';
import React, { useEffect, useState } from 'react';
import { MdRefresh } from 'react-icons/md';
import { FaDownload } from 'react-icons/fa';

import { getInvoicesList, getInvoiceSummary } from './api';
import { IZohoInvoiceItem, IZohoInvoices } from '../../../interfaces/zohoInvoices';
import GetCopyTextDiv from '../../CommonComponents/GetCopyTextDiv';
import GetStatus from '../../../utils/GetStatus';
import GetOrderByIdFilter from '../../CommonComponents/TableFilterComponents/GetOrderById';
import DateRangeFilter from '../../CommonComponents/TableFilterComponents/DateRangeFilter';
import NavigateLink from '../../CommonComponents/NavigateLink';
import ProcessInvoiceModal from './ProcessInvoiceModal';
import ExecuteInvoiceModal from './ExecuteInvoiceModal';
import { invoiceStatus } from './constants';
// import GetSummaryModal from './GetSummaryModal';
import { IGetSummaryPayload } from '../../../interfaces/invoiceActions';
import moment from 'moment';

const ZohoInvoiceList = () => {
  const [isTableLoading, setTableLoading] = useState<boolean>(false);
  const [tableData, setTableData] = useState<IZohoInvoices>({
    count: 0,
    invoiceList: []
  });
  const [clientId, setClientId] = useState<string>('');
  const [dateRange, setDateRange] = useState<{ from: string; to: string }>({
    from: '',
    to: ''
  });
  const [currentPage, setCurrentPage] = useState(1);
  const [pageLimit, setPageLimit] = useState(20);
  const [processInvoiceModal, setProcessInvoiceModal] = useState<boolean>(false);
  const [executeInvoiceModal, setExecuteInvoiceModal] = useState<boolean>(false);
  const [selectedInvoiceItem, setSelectedInvoiceItem] = useState<IZohoInvoiceItem | null>(null);
  // const [summaryModal, setSummaryModal] = useState<boolean>(false);

  const [clientIdSearchForm] = Form.useForm();

  useEffect(() => {
    handleGetInvoiceList();
  }, [clientId, dateRange, pageLimit, currentPage]);

  const handleGetInvoiceList = async () => {
    await getInvoicesList({
      setLoading: setTableLoading,
      setData: setTableData,
      clientId: clientId,
      pageLimit: pageLimit,
      skip: currentPage,
      from: dateRange?.from,
      to: dateRange?.to
    });
  };

  const onGetSummary = async ({
    clientId,
    from,
    to
  }: {
    clientId: string;
    from: string;
    to: string;
  }) => {
    const payload: IGetSummaryPayload = {
      from: moment(from?.trim()).format('YYYY-MM-DD'),
      to: moment(to?.trim()).format('YYYY-MM-DD'),
      clientId: clientId
    };

    await getInvoiceSummary({ setLoading: setTableLoading, payload });
  };
  const tableColumns: ColumnsType<IZohoInvoiceItem> = [
    {
      dataIndex: 'clientId',
      width: 190,
      fixed: 'left',
      render: (id, item) => <NavigateLink text={id} path={`/zoho-invoice-details/${item?._id}`} />,
      ...GetOrderByIdFilter({
        orderId: clientId,
        setOrderId: setClientId,
        formInstance: clientIdSearchForm,
        formId: 'clientIdSearchForm',
        placeHolder: 'Client Id',
        title: 'Client Id'
      })
    },
    {
      dataIndex: 'companyName',
      width: 190,
      title: 'Company Name',
      render: (company) => <GetCopyTextDiv text={company} />
    },

    {
      title: 'GST No',
      dataIndex: 'gstNo',
      width: 200,
      render: (id) => <GetCopyTextDiv text={id} />
    },
    {
      dataIndex: 'createdAt',
      width: 120,
      ...DateRangeFilter({ setDaterange: setDateRange, daterange: dateRange })
    },
    {
      title: 'Invoice Id',
      dataIndex: 'invoiceId',
      width: 200,
      render: (id) => <GetCopyTextDiv text={id} />
    },
    {
      title: 'Invoice Number',
      dataIndex: 'invoiceNumber',
      width: 150,
      render: (id) => <GetCopyTextDiv text={id} />
    },
    {
      title: 'Quantity of Shipment',
      dataIndex: 'quantityOfShipment',
      width: 150,
      render: (num) => <p className="font-semibold">{num}</p>
    },
    {
      title: 'Avg Rate Of Shipment',
      dataIndex: 'averageRateOfShipment',
      width: 150,
      render: (num) => <p className="font-semibold">{num}</p>
    },
    {
      title: 'Net Total Of Shipment',
      dataIndex: 'netTotalOfShipment',
      width: 150,
      render: (num) => <p className="font-semibold">{num}</p>
    },
    {
      title: 'Download Invoice',
      dataIndex: 'invoiceUrl',
      width: 120,
      render: (url) =>
        url ? (
          <Tooltip title="Download">
            <Button
              onClick={() => window.open(url, '_blank')}
              icon={<FaDownload size={25} />}
              className="border-none"
            />
          </Tooltip>
        ) : (
          <p>---</p>
        )
    },
    {
      title: 'Status',
      dataIndex: 'status',
      fixed: 'right',
      width: 150,
      key: `status-1`,
      render: (status) => (
        <GetStatus
          currStatus={status}
          PROCESSING={invoiceStatus?.INITIATED}
          SUCCESS={invoiceStatus?.APPROVED}
          FAILED={invoiceStatus?.REJECTED}
          COMPLETED={
            status === invoiceStatus?.RAISED ? invoiceStatus?.RAISED : invoiceStatus?.EXECUTED
          }
          CUSTOM_LABEL={status?.toUpperCase()?.split('_')?.join(' ')}
          SHOW_TAGS
        />
      )
    },
    {
      title: 'Summary',
      fixed: 'right',
      width: 100,
      render: (_, item) => (
        <Tooltip title="Download">
          <Button
            // onClick={() => {
            //   setSummaryModal(true);
            //   onClickActions(item);
            // }}
            onClick={() =>
              onGetSummary({ clientId: item?.clientId, from: item?.from, to: item?.to })
            }
            icon={<FaDownload size={25} />}
            className="border-none"
          />
        </Tooltip>
      )
    },
    {
      title: 'Action',
      width: 120,
      fixed: 'right',
      key: `status-2`,
      dataIndex: 'status',
      render: (status, item) => (
        <>
          {(status === invoiceStatus?.EXECUTED ||
            status === invoiceStatus?.RAISED ||
            status === invoiceStatus?.REJECTED) && (
            <Button disabled className="primary-button w-[90px] font-bold tracking-wide capitalize">
              {status}
            </Button>
          )}

          {status === invoiceStatus?.INITIATED && (
            <Button
              onClick={() => {
                setProcessInvoiceModal(true);
                onClickActions(item);
              }}
              className="primary-button w-[90px] font-bold tracking-wide">
              Process
            </Button>
          )}

          {status === invoiceStatus?.APPROVED && (
            <Button
              onClick={() => {
                setExecuteInvoiceModal(true);
                onClickActions(item);
              }}
              className="font-bold w-[90px] tracking-wide"
              type="primary">
              Execute
            </Button>
          )}
        </>
      )
    }
  ];

  const onClickActions = (item: IZohoInvoiceItem) => {
    setSelectedInvoiceItem(item);
  };

  const onPageChange: PaginationProps['onChange'] = (pageNo, limit) => {
    setCurrentPage(pageNo);
    setPageLimit(limit);
  };

  return (
    <>
      <div className="flex gap-2 items-center mb-5">
        <h2 className="text-xl font-normal m-0">Invoices</h2>
        <Tooltip title="Refresh List">
          <Button onClick={handleGetInvoiceList} loading={isTableLoading} icon={<MdRefresh />} />
        </Tooltip>
      </div>

      <div>
        <Table
          scroll={{ x: 1400 }}
          dataSource={tableData?.invoiceList}
          columns={tableColumns}
          loading={isTableLoading}
          pagination={{
            responsive: true,
            showQuickJumper: true,
            showSizeChanger: true,
            current: currentPage,
            pageSize: pageLimit,
            pageSizeOptions: [20, 30, 50],
            total: tableData?.count ? Math.ceil(tableData?.count / pageLimit) * pageLimit : 0,
            onChange: onPageChange
          }}
        />
      </div>

      <ProcessInvoiceModal
        invoiceItem={selectedInvoiceItem}
        setInvoiceItem={setSelectedInvoiceItem}
        isModalOpen={processInvoiceModal}
        setIsModalOpen={setProcessInvoiceModal}
        fetchDetails={handleGetInvoiceList}
      />

      <ExecuteInvoiceModal
        invoiceItem={selectedInvoiceItem}
        setInvoiceItem={setSelectedInvoiceItem}
        isModalOpen={executeInvoiceModal}
        setIsModalOpen={setExecuteInvoiceModal}
        fetchDetails={handleGetInvoiceList}
      />

      {/* <GetSummaryModal
        invoiceItem={selectedInvoiceItem}
        setInvoiceItem={setSelectedInvoiceItem}
        isModalOpen={summaryModal}
        setIsModalOpen={setSummaryModal}
      /> */}
    </>
  );
};

export default ZohoInvoiceList;
