import React, { FC, useState } from 'react';
import { IEmailFollowUpPayload, IZohoInvoiceDetails } from '../../../../interfaces/zohoInvoices';
import { Button, Form, Input, Modal, Spin } from 'antd';
import Formlabel from '../../../CommonComponents/FormLabel';
import { onEmailFollowUp } from '../api';

interface IEmailFollowUpModal {
  isModalOpen: boolean;
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  detailsData: IZohoInvoiceDetails | null;
}

const EmailFollowUpModal: FC<IEmailFollowUpModal> = ({
  isModalOpen,
  setIsModalOpen,
  detailsData
}) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [followUpForm] = Form.useForm();

  const onClose = () => {
    setIsModalOpen(false);
    setTimeout(() => {
      followUpForm.resetFields();
    }, 500);
  };

  const onSubmit = async (values: { amount: string; description?: string }) => {
    const payload: IEmailFollowUpPayload = {
      amount: Number(values.amount),
      description: values?.description || '',
      id: detailsData?._id || ''
    };

    await onEmailFollowUp({
      setLoading: setIsLoading,
      payload: payload,
      callbackFun: onClose
    });
  };

  return (
    <Modal title="Send follow up email" open={isModalOpen} footer={false} onCancel={onClose}>
      <Spin spinning={isLoading}>
        <Form
          layout="vertical"
          scrollToFirstError
          form={followUpForm}
          onFinish={onSubmit}
          id="followUpForm">
          <div className="flex flex-col gap-2">
            <Formlabel labelText="Amount" required />

            <Form.Item
              name="amount"
              rules={[
                { required: true, message: 'Please enter amount' },
                {
                  pattern: /^[1-9]\d*$/,
                  message: 'Amount should be valid number.'
                }
              ]}>
              <Input placeholder="Enter amount" />
            </Form.Item>
          </div>
          <div className="flex flex-col gap-2">
            <Formlabel labelText="Description" />

            <Form.Item name="description">
              <Input.TextArea placeholder="Enter amount" />
            </Form.Item>
          </div>

          <div className="flex gap-3 justify-end">
            <Button disabled={isLoading} onClick={onClose}>
              Cancel
            </Button>
            <Button
              form="followUpForm"
              type="primary"
              htmlType="submit"
              className="primary-button"
              loading={isLoading}>
              Submit
            </Button>
          </div>
        </Form>
      </Spin>
    </Modal>
  );
};

export default EmailFollowUpModal;
