import React, { useEffect, useState } from 'react';

// type
import { ColumnsType } from 'antd/es/table';

import { Button, Spin, Table } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import useOrderListDetails from '../../api';
import { IS_REFUND_ENABLED } from '../../../../../utils/config';
import RefundRequest from '../RefundRequest';
import RefundActions from '../RefundActions';
import { getDate } from '../../../../../utils/getDate';
import GetStatus from '../../../../../utils/GetStatus';
import CreateJiraTicket from './CreateJiraTicket';
import TrackingDetailsModal from './TrackingDetailsModal';
import GetCopyTextDiv from '../../../../CommonComponents/GetCopyTextDiv';
import { FaExternalLinkAlt } from 'react-icons/fa';

const OrderDetails: React.FC = () => {
  const navigate = useNavigate();
  const param = useParams();
  const {
    getDetailsOfOrder,
    PaymentDetails,
    getPaymentDetails,
    singleOrderData,
    isLoading,
    getRefundOrderDetails,
    refundDetailsData
  } = useOrderListDetails();

  const [detailsTableData, setDetailsTableData] = useState<any>([]);
  const [packageTableData, setPackageTableData] = useState([]);
  const [refundRequestModal, setRefundRequestModal] = useState<boolean>(false);
  const [refundDetailsModal, setRefundDetailsModal] = useState<boolean>(false);
  const [showDetails, setShowDetails] = useState(false);
  const [showTrackingDetails, setShowTrackingDetails] = useState<boolean>(false);

  const handleGetOrderDetails = () => {
    getDetailsOfOrder({ orderId: param?.orderId || '' });
  };

  const handlePaymentDetails = async () => {
    await PaymentDetails({ orderId: param?.orderId || '' });
    if (!showDetails) {
      setShowDetails(true);
    }
  };

  useEffect(() => {
    handleGetOrderDetails();
  }, [param]);

  useEffect(() => {
    const updatedData = Object.keys(singleOrderData)?.length
      ? [
          {
            rowHeading: 'Pickup Details',
            ...singleOrderData?.pickupDetails
          },
          {
            rowHeading: 'Delivery Details',
            ...singleOrderData?.deliveryDetails
          },
          {
            rowHeading: 'Billing Details',
            ...singleOrderData?.billingDetails
          }
        ]
      : [];

    setDetailsTableData(updatedData);

    const updatedPackageData = singleOrderData?.packageDetails?.length
      ? singleOrderData?.packageDetails?.map((item: any, index: number) => ({
          ...item,
          packageNum: index + 1
        }))
      : [];

    setPackageTableData(updatedPackageData);
  }, [singleOrderData]);

  const orderDetailsColumns: ColumnsType<any> = [
    {
      title: '',
      dataIndex: 'rowHeading',
      width: 150,
      render: (data) => (data ? <p className="m-0 font-semibold">{data}</p> : '-')
    },
    {
      title: 'Name',
      dataIndex: 'customerName',
      width: 150,
      render: (data) => (data ? data : 'NA')
    },
    {
      title: 'Address line 1',
      dataIndex: 'line1',
      width: 200,
      render: (data) => (data ? data : 'NA')
    },
    {
      title: 'Address line 2',
      dataIndex: 'line2',
      width: 200,
      render: (data) => (data ? data : 'NA')
    },
    {
      title: 'City',
      dataIndex: 'city',
      width: 130,
      render: (data) => (data ? data : 'NA')
    },
    {
      title: 'State',
      dataIndex: 'state',
      width: 130,
      render: (data) => (data ? data : 'NA')
    },
    {
      title: 'Country',
      dataIndex: 'country',
      width: 130,
      render: (data) => (data ? data : 'NA')
    },
    {
      title: 'Pincode',
      dataIndex: 'pincode',
      width: 100,
      render: (data) => (data ? data : 'NA')
    },
    {
      title: 'Mobile Num',
      dataIndex: 'mobileNumber',
      width: 130,
      render: (data) => (data ? data : 'NA')
    },
    {
      title: 'Email Id',
      dataIndex: 'email',
      width: 150,
      render: (data) => (data ? data : 'NA')
    }
  ];

  const packageDetailsColumns: ColumnsType<any> = [
    {
      title: 'Package Num',
      dataIndex: 'packageNum',
      width: 150,
      render: (data) => (data ? <p className="m-0 font-semibold">{data}</p> : '-')
    },
    {
      title: 'Weight (g)',
      dataIndex: 'weight',
      width: 150
    },
    {
      title: 'Length (cm)',
      dataIndex: 'length',
      width: 150
    },
    {
      title: 'Height (cm)',
      dataIndex: 'height',
      width: 150
    },
    {
      title: 'Width (cm)',
      dataIndex: 'width',
      width: 150
    }
  ];

  const handleGetRefundList = async () => {
    await getRefundOrderDetails({
      callbackFun: () => {
        setRefundDetailsModal(true);
      },
      orderId: param?.orderId
    });
  };

  const getRequestButton = (
    <Button className="m-0 primary-button" onClick={() => setRefundRequestModal(true)}>
      Request
    </Button>
  );

  const onClose = () => {
    navigate(-1);
    window.close();
  };

  return (
    <>
      <Button className="m-0 p-0" type="link" onClick={onClose}>
        {`<`}
        <span className="hover:underline pl-1">Back</span>
      </Button>
      <h3 className="text-center text-lg p-0 m-0 mb-3">Order Details</h3>
      <Table
        columns={orderDetailsColumns}
        dataSource={detailsTableData}
        pagination={false}
        loading={isLoading}
        scroll={{
          x: 1600
        }}
        rowKey={'rowHeading'}
      />
      <div className="my-10">
        <h3 className="text-center text-lg p-0 m-0 mb-3">Package Details</h3>
        <Table
          columns={packageDetailsColumns}
          dataSource={packageTableData}
          pagination={false}
          loading={isLoading}
          scroll={{
            x: 775
          }}
          rowKey={'rowHeading'}
        />
      </div>

      <Spin className="mt-10" spinning={isLoading}>
        <div className="flex gap-6 flex-col xl:flex-row justify-between">
          <div className="w-full xl:w-[49%] primary-box-shadow rounded-xl p-5 flex flex-col gap-3.5">
            <div className="flex items-center">
              <p className="w-44 font-semibold text-xl">Order ID</p>
              <p className="pr-2">:</p>
              <GetCopyTextDiv text={singleOrderData?.orderId} />
            </div>
            <div className="flex items-center">
              <p className="w-44 font-semibold text-xl">AWB #</p>
              <p className="pr-2">:</p>
              <GetCopyTextDiv text={singleOrderData?.trackingNumber} />
            </div>
            <div className="flex items-center">
              <p className="w-44 font-semibold text-xl">User Id</p>
              <p className="pr-2">:</p>
              <GetCopyTextDiv text={singleOrderData?.userId} />
            </div>
            <div className="flex items-center">
              <p className="w-44 font-semibold text-xl">Order Date</p>
              <p className="pr-2">:</p>
              <p>{getDate(singleOrderData?.createdAt)}</p>
            </div>
            <div className="flex items-center">
              <p className="w-44 font-semibold text-xl">Status</p>
              <p className="pr-2">:</p>
              <GetStatus
                currStatus={singleOrderData?.orderStatus}
                PROCESSING="Processing"
                SUCCESS="Order Created"
                FAILED="Order Failed"
                CANCELLED="Cancelled"
              />
            </div>

            <div className="flex items-center">
              <p className="w-44 font-semibold text-xl">Tracking Status</p>
              <p className="pr-2">:</p>
              <div className="flex gap-3 items-center">
                {singleOrderData?.trackingdetails?.length && (
                  <>
                    {typeof singleOrderData?.trackingdetails === 'object' ? (
                      <p className="font-bold text-grey-400 uppercase">
                        {singleOrderData?.trackingdetails?.length &&
                          singleOrderData?.trackingdetails[
                            singleOrderData?.trackingdetails?.length - 1
                          ]?.mainStatus}
                      </p>
                    ) : (
                      <p className="font-bold text-grey-400 uppercase">
                        {singleOrderData?.trackingdetails}
                      </p>
                    )}
                    {typeof singleOrderData?.trackingdetails === 'object' && (
                      <Button
                        className="primary-button"
                        onClick={() => setShowTrackingDetails(true)}>
                        Details
                      </Button>
                    )}
                  </>
                )}
              </div>
            </div>

            <div className="flex items-center">
              <p className="w-44 font-semibold text-xl">Pickup Vendor</p>
              <p className="pr-2">:</p>
              <p>{singleOrderData?.pickupVendor || '---------'}</p>
            </div>
            {IS_REFUND_ENABLED ? (
              <>
                <div className="flex items-center">
                  <p className="w-44 font-semibold text-xl">Refunds</p>
                  <p className="pr-2">:</p>
                  <div>
                    <div className="flex items-center gap-3">
                      <div className="flex items-center">
                        {singleOrderData?.refundStatus === 'NA' ? (
                          getRequestButton
                        ) : (
                          <GetStatus
                            currStatus={singleOrderData?.refundStatus?.toLowerCase()}
                            PROCESSING="initiated"
                            SUCCESS="approved"
                            FAILED="declined"
                            COMPLETED="refunded"
                            CUSTOM_LABEL={singleOrderData?.refundStatus?.toUpperCase()}
                          />
                        )}
                      </div>

                      <div className="m-0 ml-2">
                        {singleOrderData?.initiatedCount >= 1 ? (
                          <div className="flex gap-3">
                            <Button onClick={handleGetRefundList}>Details</Button>

                            {singleOrderData?.initiatedCount < 3 &&
                            singleOrderData?.refundStatus?.toLowerCase() === 'declined'
                              ? getRequestButton
                              : null}
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>
              </>
            ) : null}
            <div className="flex items-center">
              <p className="w-44 font-semibold text-xl">Bill amount</p>
              <p className="pr-2">:</p>
              <p>
                {singleOrderData?.totalAmount ? `Rs. ${singleOrderData?.totalAmount}` : '---------'}
              </p>
            </div>
            <div className="flex items-center">
              <p className="w-44 font-semibold text-xl">Payment Provider</p>
              <p className="pr-2">:</p>
              <p>{singleOrderData?.paymentVendor}</p>
              {singleOrderData?.paymentVendor !== 'COREYOWALLET' &&
                singleOrderData?.paymentVendor !== 'COREYO' &&
                singleOrderData?.paymentVendor != null && (
                  <Button className="primary-button ml-2" onClick={() => handlePaymentDetails()}>
                    {showDetails ? 'Refresh Details' : 'View'}
                  </Button>
                )}
            </div>
            {showDetails && (
              <>
                <div className="flex items-center">
                  <p className="w-44 font-semibold text-xl">Order Id</p>
                  <p className="pr-2">:</p>
                  <p>{getPaymentDetails?.data?.diro}</p>
                </div>
                <div className="flex items-center">
                  <p className="w-44 font-semibold text-xl">Payment Id</p>
                  <p className="pr-2">:</p>
                  <p>
                    {getPaymentDetails?.data?.dip === null
                      ? 'Not Captured'
                      : getPaymentDetails?.data?.dip}
                  </p>
                </div>
              </>
            )}
          </div>
          <div className="w-full xl:w-[49%] primary-box-shadow rounded-xl p-5 flex flex-col gap-4">
            <div className="flex items-center">
              <p className="w-44 font-semibold text-xl">Delivery Vendor</p>
              <p className="pr-2">:</p>
              <p>{singleOrderData?.deliveryVendor}</p>
            </div>
            <div className="flex items-center">
              <p className="w-44 font-semibold text-xl">Payment Type</p>
              <p className="pr-2">:</p>
              <p>{singleOrderData?.paymentType}</p>
            </div>
            <div className="flex items-center">
              <p className="w-44 font-semibold text-xl">Service</p>
              <p className="pr-2">:</p>
              <p>{singleOrderData?.serviceType}</p>
            </div>
            <div className="flex items-center">
              <p className="w-44 font-semibold text-xl">Item Desc</p>
              <p className="pr-2">:</p>
              <p>{singleOrderData?.itemDescription}</p>
            </div>
            <div className="flex items-center">
              <p className="w-44 font-semibold text-xl">Item Category</p>
              <p className="pr-2">:</p>
              <p>{singleOrderData?.itemCategory}</p>
            </div>
            <div className="flex items-center">
              <p className="w-44 font-semibold text-xl">Item Value</p>
              <p className="pr-2">:</p>
              <p>{singleOrderData?.estimatedValue}</p>
            </div>
            <div className="flex items-center">
              <p className="w-44 font-semibold text-xl">Invoice</p>
              <p className="pr-2">:</p>
              <span>
                <Button
                  href={singleOrderData?.invoiceOrder}
                  target="_blank"
                  className="primary-button">
                  View Invoice
                </Button>
              </span>
            </div>
            <div className="flex items-center">
              <p className="w-44 font-semibold text-xl">Shipment Label</p>
              <p className="pr-2">:</p>
              <span>
                <Button
                  href={singleOrderData?.shipmentLabel}
                  target="_blank"
                  className="primary-button">
                  View Shipment Label
                </Button>
              </span>
            </div>
            <CreateJiraTicket orderId={singleOrderData?.orderId} />

            <div className="flex items-center">
              <p className="w-44 font-semibold text-xl">Order Logs</p>
              <p className="pr-2">:</p>
              <span>
                <Button
                  icon={<FaExternalLinkAlt />}
                  onClick={() => window.open('/order-logs/' + singleOrderData?.orderId, '_blank')}
                  className="primary-button">
                  Logs
                </Button>
              </span>
            </div>
          </div>
        </div>
      </Spin>

      <RefundRequest
        refundRequestModal={refundRequestModal}
        setRefundRequestModal={setRefundRequestModal}
        orderTotalAmount={singleOrderData?.totalAmount}
        callBackFunction={handleGetOrderDetails}
      />

      {/* modal to show the refund details  */}
      <RefundActions
        refundActionModal={refundDetailsModal}
        setRefundActionModal={setRefundDetailsModal}
        refundData={refundDetailsData}
        showActionButtons={false}
      />

      <TrackingDetailsModal
        trackingData={singleOrderData?.trackingdetails}
        showTrackingDetails={showTrackingDetails}
        setShowTrackingDetails={setShowTrackingDetails}
      />
    </>
  );
};

export default OrderDetails;
