import { Dispatch, SetStateAction } from 'react';
import { httpClient } from '../../../../../utils/apiRequest';
import { ErrorMessage } from '../../../../../utils/ResponseMessages';
import { ICancelledOrdersResponseProps } from '../../../../../interfaces/cancelledOrders';

export const getCancelledOrders = async ({
  setLoading,
  setData,
  pageLimit,
  skip,
  orderId,
  orderStatus
}: {
  setLoading: Dispatch<SetStateAction<boolean>>;
  setData: Dispatch<SetStateAction<ICancelledOrdersResponseProps>>;
  pageLimit: number;
  skip: number;
  orderId?: string;
  orderStatus?: string;
}) => {
  setLoading(true);

  const offSet = (skip - 1) * pageLimit;
  const queryParams = `?skip=${offSet}&limit=${pageLimit}`;
  const order = orderId ? `&orderId=${orderId}` : '';
  const status = orderStatus ? `&status=${orderStatus}` : '';

  try {
    const response = await httpClient.get(
      `/order/order-cancellation${queryParams}${order}${status}`
    );
    setData({ orderList: response?.data?.data?.orderList, count: response?.data?.data?.count });
  } catch (error: any) {
    setData({ orderList: [], count: 0 });
    ErrorMessage(error?.response?.data?.message);
    console.error('Error on canceling the order ==>', error);
  } finally {
    setLoading(false);
  }
};

export const approveOrderCancellation = async ({
  setLoading,
  orderId,
  callBackFun
}: {
  setLoading: Dispatch<SetStateAction<boolean>>;
  orderId: string;
  callBackFun?: () => void;
}) => {
  setLoading(true);
  try {
    await httpClient.post(`/order/approve-order-cancellation`, { cancellationId: orderId });
    if (callBackFun) callBackFun();
  } catch (error: any) {
    ErrorMessage(error?.response?.data?.message);
    console.error('Error on approving the order cancellation ==>', error);
  } finally {
    setLoading(false);
  }
};
