import React, { FC, useEffect, useState } from 'react';
import { IOrderLogsData } from '../../../../../../interfaces/orderLogs';
import GetCopyTextDiv from '../../../../../CommonComponents/GetCopyTextDiv';
import dayjs from 'dayjs';

interface IUserInputDetails {
  logsData: IOrderLogsData | null;
  getIcons: (value: boolean | undefined | null) => JSX.Element;
}
const UserInputDetails: FC<IUserInputDetails> = ({ logsData, getIcons }) => {
  const [customData, setCustomData] = useState<
    {
      key: number;
      label: string;
      value: JSX.Element;
    }[]
  >([]);

  useEffect(() => {
    const updatedData = [
      {
        label: 'User Id',
        value: (
          <GetCopyTextDiv
            text={logsData?.checkoutApiLogs[1]?.selectedProvider?.inputData?.userId}
          />
        )
      },
      {
        label: 'Pickup Pincode',
        value: (
          <GetCopyTextDiv
            text={logsData?.checkoutApiLogs[1]?.selectedProvider?.inputData?.pickupPincode?.toString()}
          />
        )
      },
      {
        label: 'Delivery Pincode',
        value: (
          <GetCopyTextDiv
            text={logsData?.checkoutApiLogs[1]?.selectedProvider?.inputData?.deliveryPincode?.toString()}
          />
        )
      },
      {
        label: 'Weight In Grams',
        value: (
          <p>{logsData?.checkoutApiLogs[1]?.selectedProvider?.inputData?.weightInGrams + ' g'}</p>
        )
      },
      {
        label: 'Weight In KG',
        value: (
          <p>{logsData?.checkoutApiLogs[1]?.selectedProvider?.inputData?.weightInKG + ' kg'}</p>
        )
      },
      {
        label: 'Shipment Length',
        value: <p>{logsData?.checkoutApiLogs[1]?.selectedProvider?.inputData?.shipmentLength}</p>
      },
      {
        label: 'Shipment Breadth',
        value: <p>{logsData?.checkoutApiLogs[1]?.selectedProvider?.inputData?.shipmentBreadth}</p>
      },
      {
        label: 'Shipment Height',
        value: <p>{logsData?.checkoutApiLogs[1]?.selectedProvider?.inputData?.shipmentHeight}</p>
      },
      {
        label: 'Is Critical',
        value: (
          <p>{getIcons(logsData?.checkoutApiLogs[1]?.selectedProvider?.inputData?.isCritical)}</p>
        )
      },
      {
        label: 'Vendor Name',
        value: (
          <GetCopyTextDiv
            text={logsData?.checkoutApiLogs[1]?.selectedProvider?.inputData?.vendorName}
          />
        )
      },
      {
        label: 'Vendor Type',
        value: (
          <GetCopyTextDiv
            text={logsData?.checkoutApiLogs[1]?.selectedProvider?.inputData?.vendorType}
          />
        )
      },
      {
        label: 'Pickup Vendor Name',
        value: (
          <GetCopyTextDiv
            text={logsData?.checkoutApiLogs[1]?.selectedProvider?.inputData?.pickupVendorName}
          />
        )
      },
      {
        label: 'Item Category',
        value: (
          <GetCopyTextDiv
            text={logsData?.checkoutApiLogs[1]?.selectedProvider?.inputData?.itemCategory}
          />
        )
      },
      {
        label: 'Eway Bill',
        value: (
          <GetCopyTextDiv
            text={logsData?.checkoutApiLogs[1]?.selectedProvider?.inputData?.ewayBill?.toString()}
          />
        )
      },
      {
        label: 'Expiry Date',
        value: (
          <p>{getDate(logsData?.checkoutApiLogs[1]?.selectedProvider?.inputData?.expiryDate)}</p>
        )
      },
      {
        label: 'Seller Gst No',
        value: (
          <GetCopyTextDiv
            text={logsData?.checkoutApiLogs[1]?.selectedProvider?.inputData?.sellerGstNo?.toString()}
          />
        )
      },
      {
        label: 'Buyer Gst No',
        value: (
          <GetCopyTextDiv
            text={logsData?.checkoutApiLogs[1]?.selectedProvider?.inputData?.buyerGstNo?.toString()}
          />
        )
      },
      {
        label: 'Taxable Value',
        value: <p>{logsData?.checkoutApiLogs[1]?.selectedProvider?.inputData?.taxableValue}</p>
      },
      {
        label: 'COD Amount',
        value: <p>{logsData?.checkoutApiLogs[1]?.selectedProvider?.inputData?.codAmount}</p>
      },
      {
        label: 'CGST Amount',
        value: <p>{logsData?.checkoutApiLogs[1]?.selectedProvider?.inputData?.cgstAmount}</p>
      },
      {
        label: 'IGST Amount',
        value: <p>{logsData?.checkoutApiLogs[1]?.selectedProvider?.inputData?.igstAmount}</p>
      },
      {
        label: 'SGST Amount',
        value: <p>{logsData?.checkoutApiLogs[1]?.selectedProvider?.inputData?.sgstAmount}</p>
      },
      {
        label: 'Collect On Delivery',
        value: logsData?.checkoutApiLogs[1]?.selectedProvider?.inputData?.collectOnDelivery ? (
          <p>{logsData?.checkoutApiLogs[1]?.selectedProvider?.inputData?.collectOnDelivery}</p>
        ) : (
          <p>NA</p>
        )
      },
      {
        label: 'COD Order',
        value: getIcons(logsData?.checkoutApiLogs[1]?.selectedProvider?.inputData?.isCODOrder)
      },
      {
        label: 'Volumetric Weight In KG',
        value: (
          <p>{logsData?.checkoutApiLogs[1]?.selectedProvider?.inputData?.volumetricWeightInKG}</p>
        )
      },
      {
        label: 'Volumetric Weight In Grams',
        value: (
          <p>
            {logsData?.checkoutApiLogs[1]?.selectedProvider?.inputData?.volumetricWeightInGrams}
          </p>
        )
      },
      {
        label: 'Product Invoice No',
        value: (
          <GetCopyTextDiv
            text={logsData?.checkoutApiLogs[1]?.selectedProvider?.inputData?.buyerGstNo?.toString()}
          />
        )
      },
      {
        label: 'Product Invoice Date',
        value: (
          <p>
            {getDate(logsData?.checkoutApiLogs[1]?.selectedProvider?.inputData?.productInvoiceDate)}
          </p>
        )
      }
    ];

    const appendKey = updatedData?.map((item, index) => ({
      ...item,
      key: index + 1,
      label: `${index + 1}. ${item?.label}`
    }));

    setCustomData(appendKey);
  }, [logsData]);

  const getDate = (value: string | undefined | null) => {
    return value ? dayjs(value).format('dateFormat') : 'NA';
  };

  return (
    <div className="flex flex-col lg:flex-row gap-5">
      <div className="bg-white py-2 px-3 md:px-5 rounded shadow  w-full lg:w-1/2 flex flex-col gap-2">
        {customData?.slice(0, 14)?.map((item) => (
          <div key={item?.key} className="flex items-start flex-wrap">
            <div className="flex justify-between gap-1">
              <p className="w-[150px] text-md">{item?.label}</p>
              <p className="pr-2">:</p>
            </div>
            {item?.value}
          </div>
        ))}
      </div>
      <div className="bg-white py-2 px-3 md:px-5 rounded shadow  w-full lg:w-1/2 flex flex-col gap-2">
        {customData?.slice(14)?.map((item) => (
          <div key={item?.key} className="flex items-start flex-wrap">
            <div className="flex justify-between gap-1">
              <p className="w-[180px] text-md">{item?.label}</p>
              <p className="pr-2">:</p>
            </div>
            {item?.value}
          </div>
        ))}
      </div>
    </div>
  );
};

export default UserInputDetails;
