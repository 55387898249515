import { Modal, Steps } from 'antd';
import React, { FC } from 'react';
import { ICancelledOrderJourney } from '../../../../../../../interfaces/cancelledOrders';
import GetStatus from '../../../../../../../utils/GetStatus';
import dayjs from 'dayjs';

interface IJourneyModalProps {
  journeyData: ICancelledOrderJourney[];
  isModalOpen: boolean;
  setIsModalOpen: (open: boolean) => void;
}

const JourneyModal: FC<IJourneyModalProps> = ({ journeyData, isModalOpen, setIsModalOpen }) => {
  const onClose = () => {
    setIsModalOpen(false);
  };

  return (
    <Modal
      title={`Order Journey (${journeyData?.length || 0})`}
      open={isModalOpen}
      footer={false}
      onCancel={onClose}>
      {journeyData?.length > 0 ? (
        <Steps
          direction="vertical"
          size="small"
          progressDot
          current={journeyData.length}
          className="gap-3 mt-5"
          items={journeyData.map((item) => ({
            title: (
              <span className="font-semibold capitalize">
                {
                  <GetStatus
                    currStatus={item?.action?.toLowerCase()}
                    PROCESSING="requested"
                    SUCCESS="approved"
                    FAILED="rejected"
                    CUSTOM_LABEL={item?.action?.toUpperCase()}
                    SHOW_TAGS
                  />
                }
              </span>
            ),
            description: (
              <div className="max-w-[500px] mt-1">
                <div className="flex gap-2 flex-wrap">
                  <p className="flex gap-1 sm:min-w-[100px] justify-between">
                    <span>Action By</span>
                    <span>:</span>
                  </p>
                  <span className="capitalize">{item.actionBy}</span>
                </div>

                <div className="flex gap-2 flex-wrap">
                  <p className="flex gap-1 sm:min-w-[100px] justify-between">
                    <span>Reason</span>
                    <span>:</span>
                  </p>
                  <span className="capitalize">{item.reasonOfAction || 'N/A'}</span>
                </div>

                <div className="flex gap-2 flex-wrap">
                  <p className="flex gap-1 sm:min-w-[100px] justify-between">
                    <span>Date</span>
                    <span>:</span>
                  </p>
                  <span className="capitalize">
                    {dayjs(item.createdAt).format('DD MMM YYYY, HH:mm')}
                  </span>
                </div>
              </div>
            )
          }))}
        />
      ) : (
        <p className="text-center mt-4">No order journey data available.</p>
      )}
    </Modal>
  );
};

export default JourneyModal;
