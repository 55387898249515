import { Modal, Steps, Tag } from 'antd';
import React, { FC } from 'react';
import { IEmailSent, IZohoInvoiceDetails } from '../../../../interfaces/zohoInvoices';
import dayjs from 'dayjs';
import { dateFormats } from '../../../../utils/constant';
import GetCopyTextDiv from '../../../CommonComponents/GetCopyTextDiv';

interface IEmailDetailsModal {
  isModalOpen: boolean;
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  detailsData: IZohoInvoiceDetails | null;
}
const EmailDetailsModal: FC<IEmailDetailsModal> = ({
  isModalOpen,
  setIsModalOpen,
  detailsData
}) => {
  const onClose = () => {
    setIsModalOpen(false);
  };
  return (
    <Modal title="Email Details" open={isModalOpen} onCancel={onClose} footer={false}>
      <div className="max-h-[800px] overflow-y-scroll">
        <Steps
          direction="vertical"
          size="small"
          progressDot
          current={detailsData?.journey?.length}
          className="gap-3 mt-5"
          items={detailsData?.emailSent?.map((item: IEmailSent) => ({
            title: (
              <Tag color={'blue'} className="font-semibold tracking-wide">
                {dayjs(item?.createdAt).format(dateFormats?.formatOne)}
              </Tag>
            ),
            description: (
              <div className="max-w-[500px] mt-5">
                <div className="flex gap-2 flex-wrap">
                  <p className="flex gap-1 sm:min-w-[100px] justify-between">
                    <span>Amount</span>
                    <span>:</span>
                  </p>
                  <div className="capitalize">
                    <GetCopyTextDiv text={String(item?.amount)} />
                  </div>
                </div>

                <div className="flex gap-2 flex-wrap">
                  <p className="flex gap-1 sm:min-w-[100px] justify-between">
                    <span>Due Amount</span>
                    <span>:</span>
                  </p>
                  <div className="capitalize">
                    <GetCopyTextDiv text={String(item?.dueAmount)} />
                  </div>
                </div>

                <div className="flex gap-2 flex-wrap">
                  <p className="flex gap-1 sm:min-w-[100px] justify-between">
                    <span>Paid Amount</span>
                    <span>:</span>
                  </p>
                  <div className="capitalize">
                    <GetCopyTextDiv text={String(item?.paidAmount)} />
                  </div>
                </div>
              </div>
            )
          }))}
        />
      </div>
    </Modal>
  );
};

export default EmailDetailsModal;
