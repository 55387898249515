import React, { FC, useEffect, useState } from 'react';
import { Divider, Drawer, Table, Typography } from 'antd';
import { TableColumns } from '../TableColumns';
import { getCartDetail } from '../../api'; 
import { IOrderDrawer } from '../../../../../interfaces/cartList';

const { Title } = Typography;

const OrderDrawerFinx: FC<IOrderDrawer> = ({ visible, orderId, setDrawerVisible }) => {
  const [cartData, setCartData] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const { cartTableColumns, addressDetailsColumns, packageDetailsColumns } = TableColumns();

  useEffect(() => {
    if (!orderId) return;
    setCartData(null);
    setLoading(true);
  
    getCartDetail(orderId)
      .then((data: any) => {
        if (data && Object.keys(data).length > 0) {
          setCartData(data);
        } else {
          setCartData(null);
        }
      })
      .catch((error: any) => {
        console.error('Error fetching cart details:', error);
        setCartData(null);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [orderId]);
  
  const onClose = () => {
    setDrawerVisible(false);
  };

  return (
    <Drawer
      title="Cart Details for Order ID:"
      onClose={onClose}
      open={visible}
      width={'90%'}
      extra={<Title copyable={{ text: orderId }} level={5}><b>{orderId}</b></Title>}
    >
      <Title level={3} style={{ marginTop: "20px", marginBottom: "25px", textAlign: "center" }}>
        Order Details
      </Title>

        <Table
            bordered
            columns={cartTableColumns}
            loading={loading}
            rowKey="orderId"
            dataSource={cartData ? [cartData] : []} 
            pagination={false}
            expandable={{
            expandedRowRender: (record) => {
                const addressData = [
                { ...record?.billingDetails, rowHeading: 'Billing Address' },
                { ...record?.deliveryDetails, rowHeading: 'Delivery Address' }
                ];
                return (
                <div className="p-7 mx-7 my-5 primary-box-shadow flex flex-col rounded-xl">
                    <h2 className="text-xl font-bold m-0">Cart Details:</h2>
                    <h2 className="text-xl font-normal text-center m-0 mb-4">Package Details</h2>

                    <Table
                    columns={packageDetailsColumns}
                    dataSource={record?.packageDetails}
                    pagination={false}
                    loading={loading}
                    scroll={{ x: 1600 }}
                    rowKey="rowHeading"
                    />

                    <Divider />

                    <h2 className="text-xl font-normal text-center m-0 mb-4">Address Details</h2>
                    <Table
                    columns={addressDetailsColumns}
                    dataSource={addressData}
                    pagination={false}
                    loading={loading}
                    scroll={{ x: 1600 }}
                    rowKey="rowHeading"
                    />
              </div>
            );
          }
        }}
      />
       <Title level={3} style={{ marginTop: "20px", marginBottom: "25px", textAlign: "center" }}>
        Tracking Details
      </Title>
    </Drawer>
  );
};

export default OrderDrawerFinx;
