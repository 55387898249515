import { Button, Modal } from 'antd';
import React, { FC } from 'react';

interface IConfirmationModal {
  showModal: boolean;
  handleClose: () => void;
  handleConfirmUpload: () => void;
  loading: boolean;
}
const ConfirmationModal: FC<IConfirmationModal> = ({
  showModal,
  handleClose,
  handleConfirmUpload,
  loading
}) => {
  return (
    <Modal title="Alert" open={showModal} onCancel={handleClose} footer={null}>
      <p>Are you sure you want to approve the request for cancellation?</p>
      <div className="flex justify-end mt-5">
        <Button onClick={handleClose} disabled={loading} style={{ marginRight: '10px' }}>
          Cancel
        </Button>
        <Button onClick={handleConfirmUpload} loading={loading} type="primary">
          Confirm
        </Button>
      </div>
    </Modal>
  );
};

export default ConfirmationModal;
