import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getOrderLogs } from './api';
import { Button, Divider } from 'antd';
import GetCopyTextDiv from '../../../../CommonComponents/GetCopyTextDiv';
import { IOrderLogsData } from '../../../../../interfaces/orderLogs';
import { MdCheckCircleOutline, MdOutlineClose } from 'react-icons/md';
import PaymentGatewayModal from './PaymentGatewayModal';
import { ReloadOutlined } from '@ant-design/icons';
import UserInputDetails from './UserInputDetails';
import LoadingPlaceHolder from '../../../../CommonComponents/LoadingPlaceHolder';

const OrderLogs = () => {
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [logsData, setLogsData] = useState<IOrderLogsData | null>(null);
  const [paymentGatewayModal, setPaymentGatewayModal] = useState<boolean>(false);

  useEffect(() => {
    handleGetOrderLogs();
  }, []);

  const getIcons = (value: boolean | undefined | null) => {
    return value ? (
      <MdCheckCircleOutline size={25} color="green" />
    ) : (
      <MdOutlineClose size={25} color="red" />
    );
  };

  const handleGetOrderLogs = async () => {
    if (id) {
      await getOrderLogs({ orderId: id, setIsLoading: setIsLoading, setData: setLogsData });
    }
  };

  const onClose = () => {
    window.close();
  };

  return (
    <>
      <Button className="m-0 p-0" type="link" onClick={onClose}>
        {`<`}
        <span className="hover:underline pl-1">Back</span>
      </Button>

      <div className="flex gap-2 items-center">
        <h3 className="text-lg p-0 m-0">Order Logs :</h3>
        <Button loading={isLoading} onClick={handleGetOrderLogs} icon={<ReloadOutlined />} />
      </div>

      {isLoading && <LoadingPlaceHolder />}

      {logsData && !isLoading && (
        <>
          <div className="flex flex-col gap-2 mt-2">
            <div className="flex items-center flex-wrap">
              <div className="flex justify-between gap-1">
                <p className="w-[150px] text-md">1. Order Id</p>
                <p className="pr-2">:</p>
              </div>
              <GetCopyTextDiv text={logsData?.orderId} />
            </div>

            <div className="flex items-center flex-wrap">
              <div className="flex justify-between gap-1">
                <p className="w-[150px] text-md">2. User Email</p>
                <p className="pr-2">:</p>
              </div>
              <GetCopyTextDiv text={logsData?.userInfo[0]?.email} />
            </div>

            <Divider />

            <div className="flex items-center flex-wrap">
              <div className="flex justify-between gap-1">
                <h3 className="text-lg p-0 m-0 mr-1">
                  Checkout Api Passed <span className="ml-1">:</span>
                </h3>
              </div>

              {getIcons(logsData?.checkoutApiPassed)}
            </div>
          </div>

          <Divider />

          <div>
            <p className="w-[150px] text-lg mb-2">Price Breakup :</p>

            <div>
              <div className="flex items-center flex-wrap">
                <div className="flex justify-between gap-1">
                  <p className="w-[150px] text-md">1. Total Margin</p>
                  <p className="pr-2">:</p>
                </div>
                <GetCopyTextDiv
                  text={logsData?.checkoutApiLogs[1]?.priceBreakup?.totalMargin?.toString()}
                />
              </div>
              <div className="flex items-center flex-wrap">
                <div className="flex justify-between gap-1">
                  <p className="w-[150px] text-md">2. Total GST</p>
                  <p className="pr-2">:</p>
                </div>
                <GetCopyTextDiv
                  text={logsData?.checkoutApiLogs[1]?.priceBreakup?.totalGST?.toString()}
                />
              </div>
              <div className="flex items-center flex-wrap">
                <div className="flex justify-between gap-1">
                  <p className="w-[150px] text-md">3. Grand Total</p>
                  <p className="pr-2">:</p>
                </div>
                <GetCopyTextDiv
                  text={logsData?.checkoutApiLogs[1]?.priceBreakup?.grandTotal?.toString()}
                />
              </div>
            </div>
          </div>

          <Divider />

          <p className="w-[150px] text-lg mb-2">User Input Data :</p>

          <UserInputDetails logsData={logsData} getIcons={getIcons} />

          <Divider />

          <div className="flex items-center flex-wrap mb-2">
            <div className="flex justify-between gap-1">
              <h3 className="text-lg p-0 m-0 mr-1">
                Payment Api Passed <span className="ml-1">:</span>
              </h3>
            </div>

            {getIcons(logsData?.paymentApiPassed)}
          </div>

          <div className="flex items-center flex-wrap mb-2">
            <div className="flex justify-between gap-1">
              <h3 className="text-lg p-0 m-0 mr-1">
                Webhook Passed <span className="ml-1">:</span>
              </h3>
            </div>

            {getIcons(logsData?.webhookPassed)}
          </div>

          <Divider />

          <div className="flex items-center flex-wrap mb-2">
            <div className="flex justify-between gap-1">
              <h3 className="text-lg p-0 m-0 mr-1">
                Response from payment gateway <span className="ml-1">:</span>
              </h3>
            </div>

            <Button onClick={() => setPaymentGatewayModal(true)}>View Details</Button>
          </div>

          <Divider />

          <div className="flex items-center flex-wrap mb-2">
            <div className="flex justify-between gap-1">
              <h3 className="text-lg p-0 m-0 mr-1">
                Service Provider Api Passed <span className="ml-1">:</span>
              </h3>
            </div>

            {getIcons(logsData?.serviceProviderApiPassed)}
          </div>

          <Divider />

          <div className="flex justify-between gap-1">
            <h3 className="text-lg p-0 m-0 mr-1">
              Response from payment gateway <span className="ml-1">:</span>
            </h3>
          </div>

          <div className="flex flex-col gap-1 mt-2 ml-2">
            {!!logsData?.serviceProviderLogs[1]?.response?.data &&
              Object.entries(logsData?.serviceProviderLogs[1]?.response?.data).map(
                (item, index) => (
                  <div key={index} className="flex items-center flex-wrap">
                    <div className="flex justify-between gap-1">
                      <p className="w-[150px] text-md">
                        <span className="mr-1 font-semibold">{index + 1} .</span>
                        {item[0]}
                      </p>
                      <p className="pr-2">:</p>
                    </div>
                    <GetCopyTextDiv text={item[1]} />
                  </div>
                )
              )}
          </div>
        </>
      )}

      <PaymentGatewayModal
        isModalOpen={paymentGatewayModal}
        setIsModalOpen={setPaymentGatewayModal}
        detailsData={logsData?.webhookEvents}
      />
    </>
  );
};

export default OrderLogs;
