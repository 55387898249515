import React, { useRef, useState } from 'react';
import CancelOrderForm from './Partials/CancelOrderForm';
import { Form } from 'antd';
import { ICancelOrderPayload, ICancelOrdersForm } from '../../../../../interfaces/cancelOrders';
import { onCancelOrder } from './api';

const CancelOrders = () => {
  const [cancelOrderForm] = Form.useForm<ICancelOrdersForm>();
  const [isOnlyCancel, setIsOnlyCancel] = useState<boolean>(true);
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const [fileName, setFileName] = useState<string>('No File Chosen');
  const [ordersFile, setOrdersFile] = useState<string[] | null>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const onSubmit = async (formValues: ICancelOrdersForm) => {
    let payload: ICancelOrderPayload = {
      orderIds: [],
      reason: formValues?.reason || '' // optional, can be omitted
    };

    if (formValues.cancelType) {
      payload = {
        ...payload,
        orderIds: [formValues.cancelOrderID || '']
      };
    } else {
      payload = {
        ...payload,
        orderIds: ordersFile || []
      };
    }

    await onCancelOrder({
      payload,
      setLoading,
      callbackFun: () => {
        onClickRemove();
        cancelOrderForm.resetFields();
      }
    });
  };

  const onClickRemove = () => {
    setFileName('No File Chosen');
    setOrdersFile(null);
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
  };

  return (
    <>
      <h3 className="text-lg p-0 m-0 mb-3">Cancel Orders</h3>

      <CancelOrderForm
        cancelOrderForm={cancelOrderForm}
        onSubmit={onSubmit}
        isOnlyCancel={isOnlyCancel}
        setIsOnlyCancel={setIsOnlyCancel}
        onClickRemove={onClickRemove}
        fileName={fileName}
        setFileName={setFileName}
        setOrdersFile={setOrdersFile}
        ordersFile={ordersFile}
        fileInputRef={fileInputRef}
        formLoading={loading}
      />
    </>
  );
};

export default CancelOrders;
