import React, { useEffect, useState } from "react";
import { Button, Table, Tag, Modal, PaginationProps } from "antd";
import { ReloadOutlined } from "@ant-design/icons";
import { useParams } from "react-router-dom";
import { fixedChargesListAPI } from "../api";
import { useNavigate } from "react-router-dom";
import { getUserDetails, changeChargeStatus } from "../api";
import { ColumnsType } from "antd/es/table";
import FixedChargeDrawer from "./FixedChargeDrawer";

const Charges: React.FC = () => {
  const { userId } = useParams<{ userId: string }>();
  const [chargesListLoading, setChargesListLoading] = useState<boolean>(false);
  const [fixedChargesData, setFixedChargesData] = useState<any[]>([]);
  const [userData, setUserData] = useState<any>(null);
  const navigate = useNavigate();
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [pageLimit, setPageLimit] = useState<number>(20);
  const [visible, setVisible] = useState(false);
  const [confirmModalVisible, setConfirmModalVisible] = useState(false);
  const [selectedCharge, setSelectedCharge] = useState<any>(null); 
  const [users, setUsers] = useState<{ label: string; value: string }[]>([{ label: "", value: "" }]);
  const [count, setCount] = useState<number>(0);
  useEffect(() => {
    getCharges();
  }, [userId]);

  const getCharges = async (pageNo = pageNumber, limit = pageLimit, user = userId) => {
    try {
      if (!userId) return;
      const fixedChargesListApiRes = await fixedChargesListAPI({ pageNumber:pageNo,pageLimit:limit,userId:user });
      console.log(fixedChargesListApiRes, 31);
      setFixedChargesData(fixedChargesListApiRes.list);
    } catch (error) {
      console.error(error);
    }
  };
  const onPageChange: PaginationProps['onChange'] = (pageNo, limit) => {
    setPageNumber(pageNo);
    setPageLimit(limit);
    getCharges(pageNo,limit,userId);
  };

  const fetchUserDetails = async () => {
    if (userId) {
      try {
        const response = await getUserDetails(userId);
        console.log("User Details:", response);
        setUserData(response.userDetail);
      } catch (error) {
        console.error(error);
        setUserData({ name: "Unknown User", email: "Unknown Email" });
      }
    }
  };
  const refreshData = async () => {
    if (userId) {
      setChargesListLoading(true);
      await getCharges();
      setChargesListLoading(false);
    }
  };

  const handleVerifyClick = (record: any) => {
    setSelectedCharge(record);
    setConfirmModalVisible(true); 
  };

  const handleConfirm = async () => {
    try {
      if (selectedCharge) {
        const updatedCharge = await changeChargeStatus({
          _id: selectedCharge._id,
          status: "APPROVED",
        });
        console.log(updatedCharge, 125);
        setConfirmModalVisible(false); 
        refreshData(); 
      }
    } catch (error) {
      console.error("Error updating charge status:", error);
    }
  };
  useEffect(() => {
    if (userId) {
      fetchUserDetails();
      refreshData();
    }
  }, [userId]);

  const handleCancel = () => {
    setConfirmModalVisible(false); 
  };

  const columns: ColumnsType<any> = [
    {
      title: "Notice Type",
      dataIndex: "noticeType",
      key: "noticeType",
      render: (text, record) => <h1 style={{ fontWeight: "bold" }}>{record?.noticeType}</h1>,
    },
    {
      title: "ServiceType",
      dataIndex: "serviceType",
      key: "serviceType",
    },
    {
      title: "ServiceProvider",
      dataIndex: "provider",
      key: "provider",
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
    },
    {
      title: "Tax",
      dataIndex: "tax",
      key: "tax",
    },
    {
      title: "Total Amount",
      dataIndex: "totalAmount",
      key: "totalAmount",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (text, record) =>
        record?.status === "PENDING" ? (
          <Tag color="red">{record?.status}</Tag>
        ) : (
          <Tag color="green">{record?.status}</Tag>
        ),
    },
    {
      title: "Action",
      width: "15%",
      render: (record) => (
        <Button
          className="primary-button"
          onClick={() => handleVerifyClick(record)}
          disabled={record.status === "APPROVED"} 
        >
          Verify
        </Button>
      ),
    }
  ];

  return (
    <div>
      <Button type="link" onClick={() => navigate("/userlist-finx")}>
        {`<`}
        <span className="hover:underline pl-1">Back to User List</span>
      </Button>
      <div className="mb-4 flex items-center justify-between">
        <div className="flex items-center">
        <h2 className="text-xl font-normal m-0">
        Charges for User:{" "}
          {userData && userData.firstName ? (
                `${userData.firstName.trim().charAt(0).toUpperCase()}${userData.firstName.trim().slice(1).toLowerCase()}`
          ) : (
            "Loading..."
          )}
        </h2>
        </div>
        <div className="flex items-center">
          <Button size="large" onClick={refreshData} className="mr-8" icon={<ReloadOutlined className="mb-2"/>}>
            Refresh
          </Button>
          <Button size="large" className="mr-8" onClick={() => setVisible(true)}>
            Add Charges
          </Button>
        </div>
      </div>

      <Table columns={columns} 
        dataSource={fixedChargesData} 
        loading={chargesListLoading}
        pagination={{
          responsive: true,
          showQuickJumper: true,
          showSizeChanger: true,
          current: pageNumber,
          pageSize: pageLimit,
          pageSizeOptions: [20, 30, 50, 100],
          total: count, 
          onChange: onPageChange,
        }}
      />

      <FixedChargeDrawer
        visible={visible}
        onClose={() => setVisible(false)}
        userId={userId || "--"}
        refreshData={refreshData}
        users={users}
      />

      <Modal
        title="Confirm Status Change"
        visible={confirmModalVisible}
        onOk={handleConfirm}
        onCancel={handleCancel}
        okText="Confirm"
        cancelText="Cancel"
      >
        <p>Are you sure you want to change the status</p>
      </Modal>
    </div>
  );
};

export default Charges;

