import React, { FC } from 'react';
import { FaCheckCircle } from 'react-icons/fa';

interface ISuccessIconProps {
  size?: string;
}
const SuccessIcon: FC<ISuccessIconProps> = ({ size }) => {
  return (
    <FaCheckCircle
      className="text-green-500 border p-[1px] rounded-full border-green-500"
      size={size ? size : '30'}
    />
  );
};

export default SuccessIcon;
