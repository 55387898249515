import React, { Dispatch, FC, SetStateAction, useEffect, useRef, useState } from 'react';
import { Button, Form, Input, message, notification, Pagination, PaginationProps, Select, Spin, Table, Tag } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { FaDownload } from 'react-icons/fa';
import moment from 'moment';
import { Typography } from 'antd';
import Modal from 'antd/es/modal/Modal';
import { DeleteOutlined, FilePdfFilled, LoadingOutlined, ReloadOutlined, SearchOutlined, SnippetsFilled } from '@ant-design/icons';
import { deleteBulk, getAddressLableLink, getExcelLink, getRPADLableLink, getShipmentLink, mergePDF, retryFailedUpload, shipBulk } from '../../api';
import { IBulkListResponseProps } from '../../../../../interfaces/bulklist';

const { Paragraph } = Typography;
interface IHandlePortalServiceability {
  currentPortalName: string;
  isLoading: boolean;
  setIsLoading: Dispatch<SetStateAction<boolean>>;
  setActiveTab: Dispatch<SetStateAction<string>>;
  activeKey: string;
  setSelectedRaws: Dispatch<SetStateAction<any[]>>;
  fetchBulkOrders: (status?: string,) => Promise<void>;
  status: string;
  bulkOrderList: IBulkListResponseProps[];
  getBulk: (status?: string, resetFilters?: boolean, bulkId?: string, serviceType?: string, serviceProvider?:string,pageNumber?: number,
  pageLimit?: number) => Promise<void>;
  onPageChange: PaginationProps['onChange']; 
  count:number;
  pageLimit:number,
  pageNumber:number
  activeTab:any;
}

const HandleBulkOrderList: FC<IHandlePortalServiceability> = ({
  isLoading,
  setIsLoading,
  bulkOrderList,
  fetchBulkOrders,
  activeKey,
  getBulk,
  onPageChange,
  count,
  pageNumber,
  pageLimit,
  activeTab,
  status
}) => {
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [selectedBulkId, setSelectedBulkId] = useState('');
  const [open, setOpen] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [providerForm] = Form.useForm();
  const [bulkOrdersList, setBulkOrdersList] = useState<{ orders: { [key: string]: any }[]; totalPage: number; }>({ orders: [], totalPage: 0 });
  const [bulkList, setBulkList] = useState<any[]>([]);
  const [cartListData, setCartListData] = useState<any[]>([]);
  const [selectedDeleteBulkId, setSelectedDeleteBulkId] = useState<string | null>(null);
  const [bulkId, setbulkId] = useState<string>();
  const [searchServiceType, setSearchServiceType] = useState<string>('');
  const [searchServiceProvider, setSearchServiceProvider] = useState('');
  const [showServiceTypeSearch, setShowServiceTypeSearch] = useState(false);
  const [showServiceProviderSearch, setShowServiceProviderSearch] = useState(false);
  const [showBulkIdSearch, setShowBulkIdSearch] = useState(false);
  const [searchBulkId, setSearchBulkId] = useState<string>('');
  const [processingOrderId, setProcessingOrderId] = useState<string | null>(null);
  // const [status, setStatus] = useState(activeTab);
  const [processingBulkId, setProcessingBulkId] = useState<string | null>(null);
  const [bulkListLoading, setBulkListLoading] = useState<boolean>(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const bulkSearchRef = useRef<HTMLDivElement>(null);
  const serviceTypeSearchRef = useRef<HTMLDivElement>(null);
  const serviceProviderSearchRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    getBulk();
  }, []);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        bulkSearchRef.current && !bulkSearchRef.current.contains(event.target as Node) &&
        serviceTypeSearchRef.current && !serviceTypeSearchRef.current.contains(event.target as Node) &&
        serviceProviderSearchRef.current && !serviceProviderSearchRef.current.contains(event.target as Node) 

      ) {
        setShowBulkIdSearch(false);
        setShowServiceProviderSearch(false);
        setShowServiceTypeSearch(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  const handleShipNow = async (bulkId: string) => {
    setProcessingBulkId(bulkId);
    try {
      const payload = { bulkId };
      await shipBulk(payload);
      fetchBulkOrders(status);
      message.success("Bulk shipment initiated successfully.");
    } catch (error) {
      message.error("Failed to initiate bulk shipment.");
      console.error("Shipping error:", error);
    } finally {
      setProcessingBulkId(null);
    }
  };
  useEffect(() => {
    setBulkOrdersList({ orders: bulkOrderList, totalPage: 0 });
  }, [bulkOrderList]);
  const onClose = () => {
    setOpen(false);
  }
  const onCloseDelete = () => {
    setOpenDelete(false);
    setSelectedDeleteBulkId(null);
  };
  const handleDelete = async () => {
    try {
      await onDelete();
      onCloseDelete();
    } catch (error) {
      console.error("Error deleting bulk order:", error);
    }
  };
  const onDelete = async () => {
    if (!selectedDeleteBulkId) {
      message.error("No order selected for deletion.");
      return;
    }
    try {
      await deleteBulk(selectedDeleteBulkId);
      message.success("Bulk deleted successfully.");
      setBulkList((prevData) =>
        prevData.filter((item) => item.bulkId !== selectedDeleteBulkId)
      );
      setCartListData((prevCartData) =>
        prevCartData.filter((item) => item.bulkId !== selectedDeleteBulkId)
      );
      await fetchBulkOrders();
    } catch (error) {
      message.error("Failed to delete bulk.");
      console.error("Delete error:", error);
    }
  };
  const handleBulkOrderIdClick = async (bulkId: string) => {
    setSelectedBulkId(bulkId);
    setDrawerVisible(true);
  };
  const handleSearchByBulkId = async () => {
    if (!searchBulkId.trim()) {
      message.warning("Please enter a Bulk ID to search.");
      return;
    }
    try {
      setBulkListLoading(true);

      await getBulk(status, false, searchBulkId);

      setTimeout(() => {
        const filteredList = bulkOrderList.filter((order) =>
          order.bulkId.toLowerCase().includes(searchBulkId.toLowerCase())
        );
        setBulkOrdersList({ orders: filteredList, totalPage: filteredList.length });
      });
      console.log(status,145)
      setShowBulkIdSearch(false);
    } catch (error) {
      console.error("Error searching Bulk ID:", error);
      message.error("Failed to fetch bulk orders.");
    } finally {
      setBulkListLoading(false);
    }
  };
  const handleSearchByServiceType = async () => {
    if (!searchServiceType.trim()) {
      message.warning("Please select serviceType");
      return;
    } try {
      setDropdownOpen(true); 
      setBulkListLoading(true);
      await getBulk(status, false,undefined,searchServiceType);
      setTimeout(() => {
        const filteredList = bulkOrderList.filter((order) =>
          order.serviceType.toLowerCase().includes(searchServiceType.toLowerCase())
        );
        setBulkOrdersList({ orders: filteredList, totalPage: filteredList.length });
      });
      setShowBulkIdSearch(false);
    } catch (error) {
      console.error("Error searching service type:", error);
      message.error("Failed to fetch bulk orders.");
    } finally {
      setBulkListLoading(false);
    setDropdownOpen(false); 
    }
  };

  const handleSearchByServiceProvider = async () => {
    if (!searchServiceProvider.trim()) {
      message.warning("Please select service provider");
      setDropdownOpen(true); 
      return;
    } try {
      setBulkListLoading(true);
      setDropdownOpen(false); 
      await getBulk(status, false,undefined,undefined,searchServiceProvider);
      setTimeout(() => {
        const filteredList = bulkOrderList.filter((order) =>
          order.serviceProvider.toLowerCase().includes(searchServiceProvider.toLowerCase())
        );
        setBulkOrdersList({ orders: filteredList, totalPage: filteredList.length });
      });
      console.log(searchServiceProvider,193)
      setShowBulkIdSearch(false);
    } catch (error) {
      console.error("Error searching service type:", error);
      message.error("Failed to fetch bulk orders.");
    } finally {
      setBulkListLoading(false);
    }

  };

  const serviceTypeOptions: {
    label: string;
    value: string;
  }[] = [
      {
        label: 'SELF_DROP', value: 'SELF_DROP'
      },
      {
        label: 'REGISTERED_POST', value: 'REGISTERED_POST'
      },
      {
        label: 'RPAD', value: 'RPAD'
      },
      {
        label: 'ORDINARY_POST', value: 'ORDINARY_POST'
      },
    ];
  const ProviderOptions: {
    label: string;
    value: string;
  }[] = [
      {
        label: 'INDIA_POST', value: 'INDIA_POST'
      },
    ];
  const formatServiceProvider = (provider: string) => {
    const serviceProviderMap: Record<string, string> = {
      INDIA_POST: 'India Post',
    };

    return serviceProviderMap[provider] || provider;
  };
  const formatServiceType = (provider: string) => {
    const serviceProviderMap: Record<string, string> = {
      REGISTERED_POST: 'Registered Post',
      SELF_DROP: 'Self Drop',
      ORDINARY_POST: 'Ordinary Post',
      RPAD: 'rpad'
    };

    return serviceProviderMap[provider] || provider;
  };

  const columns: ColumnsType<any> = [
    {
      title: (
        <div className="relative" ref={bulkSearchRef}>
          <div className="flex items-center">
            Bulk Id
            <SearchOutlined
              className="ml-2 text-gray-500 cursor-pointer"
              onClick={() => setShowBulkIdSearch(!showBulkIdSearch)}
            />
          </div>
          {showBulkIdSearch && (
           <div className="absolute z-10 mt-2 bg-white w-50 p-3 shadow-lg border rounded">
              <Input
                autoFocus
                style={{ width: "170px" }}
                placeholder="Search by bulkId"
                value={searchBulkId}
                onChange={(e) => setSearchBulkId(e.target.value)}
                onPressEnter={handleSearchByBulkId}
                allowClear
              />
               <div className="mt-2 flex justify-end">
                <Button
                  size="small"
                  onClick={() => setShowBulkIdSearch(false)}
                  className="mr-2"
                >
                  Cancel
                </Button>
                <Button
                  type="primary"
                  size="small"
                  onClick={handleSearchByBulkId}
                >
                  Search
                </Button>
              </div>
            </div>
          )}
        </div>
      ),
      width: "12%",
      key: "bulkId",
      render: (record) => (
        <Paragraph
          copyable={{ text: record.bulkId }}
          onClick={() => handleBulkOrderIdClick(record.bulkId)}
          style={{ color: "#1677ff", textDecoration: "underline" }}
        >
          <b>{record.bulkId ? record.bulkId : "NA"}</b>
        </Paragraph>
      ),
    },
    {
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      title: 'Date',
      width: 100,
      align: 'center',
      render: (date) => {
        const bulkOrderDate = moment(date).format('DD-MM-YYYY hh:mm:ss');
        return (
          <div>{bulkOrderDate === 'Invalid date' ? '-' : bulkOrderDate}</div>
        );
      },
    },
    {
      title: 'Total',
      dataIndex: 'totalOrders',
      key: 'ordetotalOrders',
      width: 50,
      align: 'center',
      render: (orders) => <div>{orders}</div>,
    },
    {
      title: (
        <div className="relative" >
          <div className="flex items-center">
            Service Provider
            <SearchOutlined
              className="ml-2 text-gray-500 cursor-pointer"
              onClick={() => setShowServiceProviderSearch(!showServiceProviderSearch)}
            />
          </div>
          {showServiceProviderSearch && (
            <div className="absolute z-10 mt-2 bg-white w-25 p-2 shadow-lg border rounded">
              <Select
                placeholder="Search Service Provider"
                className="text-center"
                style={{ width: '200px', textAlign: 'center' }}
                options={ProviderOptions}
                value={searchServiceProvider || undefined}
                onChange={(value) => setSearchServiceProvider(value)}
                onSearch={handleSearchByServiceProvider}
                dropdownStyle={{ borderRadius: '8px' }}
                allowClear
              />
              <div className="mt-2 flex justify-end">
                <Button size="small" onClick={() => setShowServiceProviderSearch(false)} className="mr-2" >
                  Cancel
                </Button>
                <Button type="primary" size="small" onClick={handleSearchByServiceProvider} className="primary-button">
                  Search
                </Button>
              </div>
            </div>
          )}
        </div>
      ),
      align: 'center',
      width: '10%',
      render: (getUsersData) => <div>{formatServiceProvider(getUsersData?.serviceProvider)}</div>
    },
    {
      title: (
        <div className="relative">
          <div className="flex items-center text-center">
            Service Type
            <SearchOutlined
              className="ml-2 text-gray-500 cursor-pointer"
              onClick={() => setShowServiceTypeSearch(!showServiceTypeSearch)}
            />
          </div>
          {showServiceTypeSearch && (
            <div className="absolute z-10 mt-2 bg-white w-25 p-2 shadow-lg border rounded">
             <Select
                placeholder="Search Service Provider"
                className="text-center"
                style={{ width: '200px', textAlign: 'center' }}
                options={serviceTypeOptions}
                value={searchServiceType || undefined}
                onChange={(value) => setSearchServiceType(value)}
                onSearch={handleSearchByServiceType}
                dropdownStyle={{ borderRadius: '8px' }}
                allowClear
              />
              <div className="mt-2 flex justify-end">
                <Button
                  size="small"
                  onClick={() => setShowServiceTypeSearch(false)}
                  className="mr-2"> Cancel
                </Button>
                <Button
                  type="primary"
                  size="small"
                  onClick={()=>{
                    setDropdownOpen(false); 
                    handleSearchByServiceType();
                  }}
                  className='primary-button'>Search
                </Button>
              </div>
            </div>
          )}
        </div>
      ),
      align: 'center',
      width: '10%',
      render: (getUsersData) => <div>{formatServiceType(getUsersData?.serviceType)}</div>,
    },
  ];
  // const viewOrderDetails = async (orderType: string, record: any) => {
  //   setSelectedBulkId(record._id);

  //   const statusMap: Record<string, string> = {
  //     success: 'SUCCESS',
  //     processing: 'PROCESSING',
  //     dispatchedOrders: 'SHIPPED',
  //     failed: 'FAILED',
  //   };

  //   const selectedStatus = statusMap[orderType] || 'FAILED';
  //   setStatus(selectedStatus);
  //   setDrawerVisible(true);
  // };

  const retryOrder = async (record: any) => {
    try {
      setIsLoading(true);
      const response = await retryFailedUpload({ bulkId: record.bulkId });

      if (response.success) {
        notification.success({ message: "Retry initiated successfully!" });
        getBulk(status);
      } else {
        notification.error({ message: "Retry failed. Please try again." });
      }
    } catch (error) {
      console.error("Error retrying order:", error);
      notification.error({ message: "Failed to retry the order." });
    } finally {
      setIsLoading(false);
    }
  };

  if (activeKey === 'Open To Ship') {
    columns.push(
      {
        title: 'To Be Dispatched',
        key: 'ToBeDispatched',
        dataIndex: 'ToBeDispatched',
        align: 'center',
        width: 70,
        render: (_, record) => {
          if (record.serviceType === 'SELF_DROP') {
            return (
              <Button
                className="primary-button"
                onClick={() => handleShipNow(record.bulkId)}
                disabled={record.status === "PROCESSING" || processingBulkId === record.bulkId}
              >
                {processingBulkId === record.bulkId ? "Processing..." : "Ship Now"}
              </Button>
            );
          } else {
            return <h6 className="secondary-button">--</h6>;
          }
        }
      },
      // Zip file
      {
        title: 'Notices (ZIP)',
        key: 'bulkShipmentLink',
        width: '12%',
        align: 'center',
        render: (orders: any) => {

          const handleGenerateZIP = async () => {
            try {
              setProcessingOrderId(orders.bulkId);
              orders.bulkShipmentLink = "PROCESSING";
              setBulkList([...bulkList]);

              const response = await getShipmentLink({ bulkId: orders.bulkId });
              if (response?.bulkShipmentLink) {
                window.open(response.bulkShipmentLink, "_blank");
              }
              await fetchBulkOrders(status);
            } catch (error) {
              console.error("Error generating ZIP file", error);
            } finally {
              setProcessingOrderId(null);
            }
          };

          if (!orders.bulkShipmentLink) {
            return <>
              <Button
                icon={<FilePdfFilled />}
                onClick={handleGenerateZIP}
                className='me-2'
              >
                Generate
              </Button>
            </>
          }
          if (orders.bulkShipmentLink === 'PROCESSING') {
            return <Button>
              In Process<Spin indicator={<LoadingOutlined spin />} size="small" />
            </Button>
          }
          return (
            <>
              <Button
                icon={<FaDownload />}
                className="primary-button me-1"
                href={orders.bulkShipmentLink}
                target="_blank"
              >
                Download
              </Button>
              <Button onClick={handleGenerateZIP} icon={<ReloadOutlined />} />
            </>
          )
        },
      },
      // Address lable
      {
        title: "Address Label",
        key: "bulkAddressLabel",
        width: "13%",
        align: "center",
        render: (record) => {
          if (record.serviceType === "SELF_DROP") {
            return <h4>--</h4>;
          }
          const handleGenerateAddressLabel = async () => {
            try {
              setProcessingOrderId(record.bulkId);
              record.bulkAddressLable = "PROCESSING";
              setBulkList([...bulkList]);

              const response = await getAddressLableLink({ bulkId: record.bulkId });

              if (response?.bulkAddressLabel) {
                window.open(response.bulkAddressLabel, "_blank");
              }
              fetchBulkOrders(status);

            } catch (error) {
              console.error("Error generating address label:", error);
            } finally {
              setProcessingOrderId(null);
            }
          };
          if (!record.bulkAddressLable) {
            return (
              <Button
                icon={<SnippetsFilled />}
                className="me-2"
                onClick={handleGenerateAddressLabel}
              >
                Generate
              </Button>
            );
          }
          if (record.bulkAddressLable === "PROCESSING") {
            return (
              <Button>
                In Process <Spin indicator={<LoadingOutlined spin />} size="small" />
              </Button>
            );
          }
          return (
            <>
              <Button
                icon={<SnippetsFilled />}
                className="primary-button me-1"
                href={record.bulkAddressLable}
                target="_blank"
              >
                Address Label
              </Button>
              <Button onClick={handleGenerateAddressLabel} icon={<ReloadOutlined />} />
            </>
          );
        },
      },
      // RPAD Lable
      {
        title: 'Dl Acknowledge Label',
        key: 'bulkRpadLabel',
        width: '10%',
        align: 'center',
        render: (record) => {
          if (record.serviceType === "SELF_DROP") {
            return <h4>--</h4>;
          }
          const handleGenerateLabel = async () => {
            try {
              setProcessingOrderId(record.bulkId);
              record.bulkRpadLabel = "PROCESSING";
              setBulkList([...bulkList]);
              const response = await getRPADLableLink({ bulkId: record.bulkId });
              if (response?.bulkRpadLabel) {
                window.open(response.bulkRpadLabel, "_blank");
              }
              await fetchBulkOrders(status);
            } catch (error) {
              console.error("Error generating rpad label:", error);
            } finally {
              setProcessingOrderId(null);
            }
          };

          if (!record.bulkRpadLabel) {
            return (
              <Button
                icon={<SnippetsFilled />}
                className="me-2"
                onClick={handleGenerateLabel}
              >
                Generate
              </Button>
            );
          }
          if (record.bulkRpadLabel === 'PROCESSING') {
            return <Button>
              In Process<Spin indicator={<LoadingOutlined spin />} size="small" />
            </Button>
          }
          return (
            <>
              <Button
                icon={<SnippetsFilled />}
                className="primary-button me-1"
                href={record.bulkRpadLabel}
                target="_blank"
              >
                Label
              </Button>
              <Button onClick={handleGenerateLabel} icon={<ReloadOutlined />} />
            </>
          )
        },
      },
      // ExcelFile
      {
        title: 'Shipment (XLSX)',
        key: 'bulkExcelLink',
        width: '12%',
        align: 'center',
        render: (record) => {
          if (record.serviceType === "SELF_DROP") {
            return <h4>--</h4>;
          }
          const handleGenerateExcel = async () => {
            try {
              setProcessingOrderId(record.bulkId);
              record.bulkExcelLink = "PROCESSING";
              setBulkList([...bulkList]);
              const response = await getExcelLink({ bulkId: record.bulkId });
              if (response?.bulkExcelLink) {
                window.open(response.bulkExcelLink, "_blank");
              }
              await fetchBulkOrders(status);

            } catch (error) {
              console.error("Error generating Excel file", error);
            } finally {
              setProcessingOrderId(null);
            }
          };
          if (!record.bulkExcelLink) {
            return <>
              <Button
                icon={<FilePdfFilled />}
                className='me-2'
                onClick={handleGenerateExcel}
              >
                Generate
              </Button>
            </>
          }
          if (record.bulkExcelLink === 'PROCESSING') {
            return <Button>
              In Process<Spin indicator={<LoadingOutlined spin />} size="small" />
            </Button>
          }
          return (
            <>
              <Button
                icon={<FaDownload />}
                className="primary-button me-1"
                href={record.bulkExcelLink}
                target="_blank"
              >
                Download
              </Button>
              <Button onClick={handleGenerateExcel} icon={<ReloadOutlined />} />
            </>
          )
        },
      },
      {
        title: 'Delete',
        key: 'Delete',
        dataIndex: 'delete',
        align: 'center',
        width: 45,
        render: (_: any, record: any) => {
          const showDeleteModal = () => {
            setSelectedDeleteBulkId(record.bulkId);
            setOpenDelete(true);
          };
          return (
            <DeleteOutlined style={{ color: '#1890ff' }} onClick={showDeleteModal} />
          );
        }
      },
    )
  }
  if (activeKey === 'Shipment Placed') {
    columns.splice(5, 0,
      // label
      {
        title: 'Label (PDF)',
        key: 'label',
        width: 150,
        align: 'center',
        render: (record) => {
          const handleGenerateLabel = async () => {
            try {
              setProcessingOrderId(record.bulkId);
              record.label = "PROCESSING";
              setBulkList([...bulkList]);

              const response = await mergePDF({ bulkId: record.bulkId, type: 'label' });

              if (response?.label) {
                record.label = response.label;
                setBulkList([...bulkList]);
              }

              await fetchBulkOrders(status);
            } catch (error) {
              console.error("Error generating label", error);
            } finally {
              setProcessingOrderId(null);
            }
          };
          if (!record.label) {
            return (
              <Button icon={<FilePdfFilled />} className="me-2" onClick={handleGenerateLabel}>
                Generate
              </Button>
            );
          }
          if (record.label === "PROCESSING") {
            return (
              <Button>
                In Process <Spin indicator={<LoadingOutlined spin />} size="small" />
              </Button>
            );
          }
          return (
            <>
              <Button icon={<FaDownload />} className="primary-button me-1" href={record.label} target="_blank">
                Download
              </Button>
              <Button onClick={handleGenerateLabel} icon={<ReloadOutlined />} />
            </>
          );
        },
      }
    );
    columns.splice(6, 0,
      //Invoice
      {
        title: 'Invoice',
        key: 'invoice',
        width: 150,
        align: 'center',
        render: (record) => {
          const handleGenerateInvoice = async () => {
            try {
              setProcessingOrderId(record.orderId);
              record.invoice = "PROCESSING";
              setBulkList([...bulkList]);

              const response = await mergePDF({ bulkId: record.bulkId, type: 'invoice' });

              if (response?.invoice) {
                record.invoice = response.invoice;
                setBulkList([...bulkList]);
              }

              await getBulk(status);
            } catch (error) {
              console.error("Error generating invoice", error);
            } finally {
              setProcessingOrderId(null);
            }
          };
          if (!record.invoice) {
            return (
              <Button icon={<FilePdfFilled />} className="me-2" onClick={handleGenerateInvoice}>
                Generate
              </Button>
            );
          }
          if (record.invoice === "PROCESSING") {
            return (
              <Button>
                In Process <Spin indicator={<LoadingOutlined spin />} size="small" />
              </Button>
            );
          }
          return (
            <>
              <Button icon={<FaDownload />} className="primary-button me-1" href={record.invoice} target="_blank">
                Download
              </Button>
              <Button onClick={handleGenerateInvoice} icon={<ReloadOutlined />} />
            </>
          );
        },
      },
    )
    columns.splice(3, 0,
      {
        title: 'Success',
        dataIndex: 'successfullOrders',
        key: 'successfullOrders',
        width: 80,
        align: 'center',
        render: (successOrders) => (
          <Tag color="green" style={{ cursor: "pointer" }}> {successOrders}</Tag>
        )
      },
      {
        title: 'Failed',
        dataIndex: 'failedOrders',
        key: 'failedOrders',
        width: 150,
        align: 'center',
        render: (failedOrders, record) => (
          <div>
            <Tag color="red" style={{ cursor: "pointer" }} >
              {failedOrders}
            </Tag>
            <Button type="link" onClick={() => retryOrder(record)} style={{ marginLeft: 8 }}>
              Retry Order
            </Button>
          </div>
        )
      }

    )
  }
  if (activeKey === 'In-Process') {
    columns.splice(3, 0,
      {
        title: 'Notices (ZIP)',
        key: 'bulkShipmentLink',
        width: '20%',
        align: 'center',
        render: (orders: any) => {

          const handleGenerateZIP = async () => {
            try {
              setProcessingOrderId(orders.bulkId);
              orders.bulkShipmentLink = "PROCESSING";
              setBulkList([...bulkList]);

              const response = await getShipmentLink({ bulkId: orders.bulkId });
              if (response?.bulkShipmentLink) {
                window.open(response.bulkShipmentLink, "_blank");
              }
              await fetchBulkOrders(status);
            } catch (error) {
              console.error("Error generating ZIP file", error);
            } finally {
              setProcessingOrderId(null);
            }
          };

          if (!orders.bulkShipmentLink) {
            return <>
              <Button
                icon={<FilePdfFilled />}
                onClick={handleGenerateZIP}
                className='me-2'
              >
                Generate
              </Button>
            </>
          }
          if (orders.bulkShipmentLink === 'PROCESSING') {
            return <Button>
              In Process<Spin indicator={<LoadingOutlined spin />} size="small" />
            </Button>
          }
          return (
            <>
              <Button
                icon={<FaDownload />}
                className="primary-button me-1"
                href={orders.bulkShipmentLink}
                target="_blank"
              >
                Download
              </Button>
              <Button onClick={handleGenerateZIP} icon={<ReloadOutlined />} />
            </>
          )
        },
      },
      {
        title: (
          <div className="relative">
            <div className="flex items-center text-center">
              Service Type
              <SearchOutlined
                className="ml-2 text-gray-500 cursor-pointer"
                onClick={() => setShowServiceTypeSearch(!showServiceTypeSearch)}
              />
            </div>
            {showServiceTypeSearch && (
              <div className="absolute z-10 mt-2 bg-white w-25 p-2 shadow-lg border rounded">
                <Select
                  placeholder="Search by ServiceType"
                  className="mt-5"
                  style={{ width: '200px' }}
                  options={serviceTypeOptions}
                  value={searchServiceType}
                  onChange={(value) => setSearchServiceType(value)}
                  onSearch={handleSearchByServiceType}
                  allowClear
                />
                <div className="mt-2 flex justify-end">
                  <Button
                    size="small"
                    onClick={() => setShowServiceTypeSearch(false)}
                    className="mr-2"> Cancel
                  </Button>
                  <Button
                    type="default"
                    size="small"
                    onClick={handleSearchByServiceType}
                    className='primary-button'>Search
                  </Button>
                </div>
              </div>
            )}
          </div>
        ),
        align: 'center',
        width: '10%',
        render: (getUsersData) => <div>{formatServiceType(getUsersData?.serviceType)}</div>,
      },
    );
    columns.pop();
  }

  if (activeKey === 'To Be Dispatched') {

    columns.splice(3, 0,
      {
        title: 'Proccess Orders',
        dataIndex: 'inProccessOrders',
        key: 'inProccessOrders',
        width: 100,
        align: 'center',
        render: (inProccessOrders) => (
          // <Tag color="yellow" style={{ cursor: "pointer" }} onClick={() => viewOrderDetails('processing', record)}> {inProccessOrders}</Tag>
          <Tag color="yellow" style={{ cursor: "pointer" }}> {inProccessOrders}</Tag>
        )
      },
      {
        title: 'Dispatched Orders',
        dataIndex: 'toBeDispatchedOrders',
        key: 'toBeDispatchedOrders',
        width: 100,
        align: 'center',
        render: (toBeDispatchedOrders) => (
          <div>
            <Tag color="green" style={{ cursor: "pointer" }}>
              {toBeDispatchedOrders}
            </Tag>
          </div>
        )
      },
      {
        title: 'Address Lable',
        key: 'bulkAddressLabelLink',
        width: 150,
        align: 'center',
        render: (text, record) => {

          const getShipmentLabel = async (bulkId: string) => {
            const shipmentLableDetails: any = await ({ bulkId: bulkId });
            if (shipmentLableDetails?.data?.url) {
              window.open(shipmentLableDetails?.data.url, '_blank');
            }
          }
          return (
            <Button
              onClick={() => getShipmentLabel(record._id)}
              icon={<SnippetsFilled />}
              target="_blank"
              className="primary-button"
              disabled={record.failedOrders === record.orders || (activeKey === 'To Be Dispatched' && record.inProccessOrders > 0)}>
              Lable Download
            </Button>
          )
        }
      },
      {
        title: 'Dl Acknowledge Label',
        key: 'bulkAddressLabelLink',
        width: 130,
        align: 'center',
        render: (text, record) => {

          const getShipmentLabel = async (bulkId: string) => {
            const shipmentLableDetails: any = await ({ bulkId: bulkId });
            if (shipmentLableDetails?.data?.url) {
              window.open(shipmentLableDetails?.data.url, '_blank');
            }
          }
          return (
            <Button
              onClick={() => getShipmentLabel(record._id)}
              icon={<SnippetsFilled />}
              target="_blank"
              className="primary-button"
              disabled={record.failedOrders === record.orders || (activeKey === 'To Be Dispatched' && record.inProccessOrders > 0)}>
              Lable Download
            </Button>
          )
        }
      },
      {
        title: 'Shipment (XLSX)',
        key: 'bulkExcelLink',
        width: 150,
        align: 'center',
        render: (orders: any) => {
          return (
            <Button
              icon={<FaDownload />}
              href={orders.bulkExcelLink}
              target="_blank"
              className="primary-button">
              Download
            </Button>
          )
        }
      }
    );
    columns.pop();
  }
  const handleSubmit = async (formValues: any) => {
    setOpen(false);
    await ({ bulkId: bulkId, serviceProvider: formValues.serviceProvider, serviceType: formValues.serviceType })
  };
  return (
    <>
      <div>
        <div className="mb-4 flex items-center justify-between" />
        <div>
        <Table
          bordered
          columns={columns}
          loading={isLoading}
          rowKey="_id"
          scroll={{ x: 1616 }}
          dataSource={bulkOrderList}
          pagination={{
            responsive: true,
            showQuickJumper: true,
            showSizeChanger: true,
            current: pageNumber,
            pageSize: pageLimit,
            total:count,
            pageSizeOptions: [20, 30, 50, 100],
            onChange: onPageChange,
            hideOnSinglePage: false, 
            showLessItems: false, 
          }}
        />
        </div>
      </div>
      <Modal
        open={open}
        onCancel={onClose}
        footer={false}>
        <div>
          <Form form={providerForm} layout="vertical" id="addProviderForms" onFinish={handleSubmit}>
            <div className="flex flex-col gap-2">
              <p>Are you sure you want to ship the bulk? </p>
            </div>
            <div className="my-5 flex justify-end gap-5">
              <Button className="px-5" onClick={onClose}>Cancel</Button>
              <Button htmlType="submit" form="addProviderForms" className="px-5 primary-button">
                Ship
              </Button>
            </div>
          </Form>
        </div>
      </Modal>
      <Modal
        title="Delete Bulk Order"
        visible={openDelete}
        onCancel={onCloseDelete}
        footer={false}
      >
        <div>
          <div className="flex flex-col gap-2">
            <p>Are you sure you want to delete the selected bulk order?</p>
          </div>
          <div className="my-5 flex justify-end gap-5">
            <Button onClick={onCloseDelete}>Cancel</Button>
            <Button onClick={handleDelete} type="primary" >
              Delete
            </Button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default HandleBulkOrderList;
