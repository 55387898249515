import { Dispatch, SetStateAction } from 'react';
import { httpClient } from '../../../utils/apiRequest';
import { ErrorMessage, SuccessMessage } from '../../../utils/ResponseMessages';
import {
  IEmailFollowUpPayload,
  IZohoInvoiceDetails,
  IZohoInvoices
} from '../../../interfaces/zohoInvoices';
import {
  IExtendInvoicePayload,
  IGetSummaryPayload,
  IProcessInvoicePayload
} from '../../../interfaces/invoiceActions';
import { JsonToExcel } from '../../../utils/Excel-Conversion/JsonToExcel';

export const getInvoicesList = async ({
  setLoading,
  setData,
  clientId,
  from,
  to,
  pageLimit,
  skip
}: {
  setLoading: Dispatch<SetStateAction<boolean>>;
  setData: Dispatch<SetStateAction<IZohoInvoices>>;
  pageLimit: number;
  skip: number;
  clientId?: string;
  from?: string;
  to?: string;
}) => {
  const clientSearch = clientId ? `&clientId=${clientId}` : '';
  const dateRangeQuery = from ? `&from=${from}&to=${to}` : '';
  const offSet = (skip - 1) * pageLimit;
  const queryParams = `?skip=${offSet}&limit=${pageLimit}`;

  setLoading(true);
  try {
    const response = await httpClient.get(
      `/invoicing/invoices/${queryParams}${clientSearch}${dateRangeQuery}`
    );
    setData({
      count: response?.data?.data?.count,
      invoiceList: response?.data?.data?.invoiceList
    });
  } catch (error: any) {
    setData({
      count: 0,
      invoiceList: []
    });
    console.error('Error while fetching client list', error?.response?.data?.message);
    ErrorMessage(error?.response?.data?.message);
  } finally {
    setLoading(false);
  }
};

export const getInvoiceDetails = async ({
  setLoading,
  invoiceId,
  setData
}: {
  setLoading: Dispatch<SetStateAction<boolean>>;
  invoiceId: string;
  setData: Dispatch<SetStateAction<IZohoInvoiceDetails | null>>;
}) => {
  setLoading(true);

  try {
    const response = await httpClient.get(`/invoicing/invoice/${invoiceId}`);
    setData(response?.data?.data);
  } catch (error: any) {
    console.error('Error while fetching client list', error?.response?.data?.message);
    ErrorMessage(error?.response?.data?.message);
    setData(null);
  } finally {
    setLoading(false);
  }
};

export const processInvoice = async ({
  setLoading,
  payload,
  callbackFun
}: {
  setLoading: Dispatch<SetStateAction<boolean>>;
  payload: IProcessInvoicePayload;
  callbackFun?: () => void;
}) => {
  setLoading(true);
  try {
    await httpClient.post('/invoicing/process', payload);
    SuccessMessage('Invoice Processed successfully');
    if (callbackFun) callbackFun();
  } catch (error: any) {
    console.error('Error while processing the invoice', error?.response?.data?.message);
    ErrorMessage(error?.response?.data?.message);
  } finally {
    setLoading(false);
  }
};

export const executeInvoice = async ({
  setLoading,
  payload,
  callbackFun
}: {
  setLoading: Dispatch<SetStateAction<boolean>>;
  payload: IExtendInvoicePayload;
  callbackFun?: () => void;
}) => {
  setLoading(true);
  try {
    await httpClient.post('/invoicing/execute', payload);
    SuccessMessage('Invoice Executed successfully');
    if (callbackFun) callbackFun();
  } catch (error: any) {
    console.error('Error while executing the invoice', error?.response?.data?.message);
    ErrorMessage(error?.response?.data?.message);
  } finally {
    setLoading(false);
  }
};

export const getInvoiceSummary = async ({
  setLoading,
  payload,
  callBack
}: {
  setLoading: Dispatch<SetStateAction<boolean>>;
  payload: IGetSummaryPayload;
  callBack?: () => void;
}) => {
  setLoading(true);
  const query = `?clientId=${payload?.clientId}&from=${payload?.from}&to=${payload?.to}`;
  try {
    const response = await httpClient.get(`/invoicing/report${query}`);
    JsonToExcel({
      dataFile: response?.data?.data,
      filename: `Invoice-Summary-${payload?.clientId}`
    });
    if (callBack) callBack();
  } catch (error: any) {
    console.error('Error while fetching invoice summary', error?.response?.data?.message);
    ErrorMessage(error?.response?.data?.message || 'Something went wrong ');
  } finally {
    setLoading(false);
  }
};

export const onEmailFollowUp = async ({
  setLoading,
  payload,
  callbackFun
}: {
  setLoading: Dispatch<SetStateAction<boolean>>;
  payload: IEmailFollowUpPayload;
  callbackFun?: () => void;
}) => {
  setLoading(true);
  try {
    const response = await httpClient.post('/invoicing/credit', payload);
    SuccessMessage(response?.data?.message || 'Successful');
    if (callbackFun) callbackFun();
  } catch (error: any) {
    console.error('Error while sending email follow up', error?.response?.data?.message);
    ErrorMessage(error?.response?.data?.message);
  } finally {
    setLoading(false);
  }
};
