import { Modal, Input } from 'antd';
import React, { useState, useMemo } from 'react';

interface IOrderIdsModalProps {
  visible: boolean;
  onClose: () => void;
  orderIds: string[];
}

const OrderIdsModal: React.FC<IOrderIdsModalProps> = ({ visible, onClose, orderIds }) => {
  const [searchTerm, setSearchTerm] = useState('');

  const filteredOrderIds = useMemo(() => {
    return orderIds.filter((id) => id.toLowerCase().includes(searchTerm.toLowerCase()));
  }, [searchTerm, orderIds]);

  const onCloseModal = () => {
    setSearchTerm('');
    onClose();
  };
  return (
    <Modal
      title={`Order IDs - ${orderIds?.length}`}
      open={visible}
      onCancel={onCloseModal}
      footer={false}>
      {orderIds?.length > 4 && (
        <Input
          placeholder="Search Order ID..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className="mb-3"
        />
      )}

      {filteredOrderIds.length > 0 ? (
        <ul className="max-h-60 overflow-auto mt-4">
          {filteredOrderIds.map((id) => (
            <li key={id} className="py-1 border-b">
              {id}
            </li>
          ))}
        </ul>
      ) : (
        <p>No matching orders found.</p>
      )}
    </Modal>
  );
};

export default React.memo(OrderIdsModal);
