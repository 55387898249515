import { ErrorMessage, SuccessMessage } from '../../../utils/ResponseMessages';
import { httpClient } from '../../../utils/apiRequest';
import { Dispatch, SetStateAction } from 'react';
import {
  IAddClientPayload,
  IClientDetailsApiResponse,
  IClientList
} from '../../../interfaces/invoicingEngine';
import { IInvoiceDetails, IInvoiceList } from '../../../interfaces/invoiceListAndDetails';
import { IInitiateInvoicePayload } from '../../../interfaces/invoiceActions';

export const getClientList = async ({
  setLoading,
  setData,
  pageLimit,
  skip
}: {
  setLoading: Dispatch<SetStateAction<boolean>>;
  setData: Dispatch<SetStateAction<IClientList>>;
  pageLimit: number;
  skip: number;
}) => {
  setLoading(true);

  const offSet = (skip - 1) * pageLimit;
  const queryParams = `?skip=${offSet}&limit=${pageLimit}`;

  try {
    const response = await httpClient.get(`/invoicing/clients${queryParams}`);
    setData(response?.data?.data);
  } catch (error: any) {
    setData({ count: 0, clientsList: [] });
    console.error('Error while fetching client list', error?.response?.data?.message);
    ErrorMessage(error?.response?.data?.message);
  } finally {
    setLoading(false);
  }
};

export const addNewClient = async ({
  setLoading,
  payload,
  callBack
}: {
  payload: IAddClientPayload;
  setLoading: Dispatch<SetStateAction<boolean>>;
  callBack?: () => void;
}) => {
  setLoading(true);
  try {
    await httpClient.post('/invoicing/users', payload);
    if (callBack) callBack();
  } catch (error: any) {
    console.error('Error while adding client', error?.response?.data?.message);
    ErrorMessage(error?.response?.data?.message);
  } finally {
    setLoading(false);
  }
};

export const getClientDetails = async ({
  setLoading,
  clientId,
  setData
}: {
  setLoading: Dispatch<SetStateAction<boolean>>;
  setData: Dispatch<SetStateAction<IClientDetailsApiResponse | null>>;
  clientId: string;
}) => {
  setLoading(true);
  try {
    const response = await httpClient.get(`/invoicing/clients/${clientId}`);
    setData(response?.data?.data);
  } catch (error: any) {
    setData(null);
    console.error('Error while fetching client list', error?.response?.data?.message);
    ErrorMessage(error?.response?.data?.message);
  } finally {
    setLoading(false);
  }
};

export const verifyUserApi = async ({
  setLoading,
  emailId,
  callbackFun
}: {
  setLoading: Dispatch<SetStateAction<boolean>>;
  emailId: string;
  callbackFun?: (id: string) => void;
}) => {
  setLoading(true);
  try {
    const response = await httpClient.get(`/invoicing/user/${emailId}`);
    if (callbackFun) callbackFun(response?.data?.data?.user_id);
  } catch (error: any) {
    if (callbackFun) callbackFun('');
    console.error('Error while verifying the user', error?.response?.data?.message);
    ErrorMessage(error?.response?.data?.message);
  } finally {
    setLoading(false);
  }
};

export const getInvoiceList = async ({
  setLoading,
  clientId,
  setData
}: {
  setLoading: Dispatch<SetStateAction<boolean>>;
  clientId: string;
  setData: Dispatch<SetStateAction<IInvoiceList>>;
}) => {
  setLoading(true);
  try {
    const response = await httpClient.get(
      `/invoicing/invoices/?skip=0&limit=10&clientId=${clientId}`
    );
    setData({ count: response?.data?.data?.count, invoiceList: response?.data?.data?.invoiceList });
  } catch (error: any) {
    setData({ count: 0, invoiceList: [] });
    console.error('Error while getting invoice list', error?.response?.data?.message);
    ErrorMessage(error?.response?.data?.message);
  } finally {
    setLoading(false);
  }
};

export const getInvoiceDetails = async ({
  setLoading,
  invoiceId,
  setData
}: {
  setLoading: Dispatch<SetStateAction<boolean>>;
  invoiceId: string;
  setData: Dispatch<SetStateAction<IInvoiceDetails | null>>;
}) => {
  setLoading(true);
  try {
    const response = await httpClient.get(`/invoicing/invoice/${invoiceId}`);
    setData(response?.data?.data);
  } catch (error: any) {
    setData(null);
    console.error('Error while getting invoice details', error?.response?.data?.message);
    ErrorMessage(error?.response?.data?.message);
  } finally {
    setLoading(false);
  }
};

export const initiateInvoice = async ({
  setLoading,
  payload,
  callBack
}: {
  setLoading: Dispatch<SetStateAction<boolean>>;
  payload: IInitiateInvoicePayload;
  callBack?: () => void;
}) => {
  setLoading(true);
  try {
    await httpClient.post('/invoicing/initiate', payload);
    SuccessMessage('Invoice initiated successfully');
    if (callBack) callBack();
  } catch (error: any) {
    console.error('Error while initiating invoice', error?.response?.data?.message);
    ErrorMessage(error?.response?.data?.message);
  } finally {
    setLoading(false);
  }
};
