import { Dispatch, SetStateAction } from 'react';
import { ICancelOrderPayload } from '../../../../../interfaces/cancelOrders';
import { httpClient } from '../../../../../utils/apiRequest';
import { ErrorMessage } from '../../../../../utils/ResponseMessages';

export const onCancelOrder = async ({
  payload,
  setLoading,
  callbackFun
}: {
  payload: ICancelOrderPayload;
  setLoading: Dispatch<SetStateAction<boolean>>;
  callbackFun: () => void;
}) => {
  setLoading(true);
  try {
    const response = await httpClient.post(`/order/request-order-cancellation`, payload);
    if (response?.data?.status === 404) {
      ErrorMessage(response?.data?.message);
    } else {
      callbackFun();
    }
  } catch (error: any) {
    ErrorMessage(error?.response?.data?.message);
    console.error('Error on canceling the order ==>', error);
  } finally {
    setLoading(false);
  }
};
